<template>
  <div class="table">
    <v-data-table
      :class="{ 'filters-opened': areFiltersOpened }"
      :headers="headers"
      fixed-header
      hide-default-header
      :loading="loading"
      :items="getAllTransaction"
      item-key="id"
      class="elevation-1 list__table"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
    >
      <template v-slot:header="{ props: { headers } }">
        <thead class="bg-blue-mode text-light">
          <tr class="bg-blue-mode text-light">
            <th
              class="bg-blue-mode text-light"
              v-for="(h, idx) in headers"
              :key="idx"
              :class="h.class"
            >
              <span>{{ h.text }}</span>
            </th>
          </tr>
        </thead>
      </template>
      <template v-slot:item.Date="{ item }">
        <div class="stepper-table-content" v-if="item.transactionIn">
          {{ item.transactionIn.date | formatDate }}
        </div>
        <div class="stepper-table-content" v-if="item.transactionP2P">
          {{ item.transactionP2P.date | formatDate }}
        </div>
        <div class="stepper-table-content" v-if="item.transactionOut">
          {{ item.transactionOut.date | formatDate }}
        </div>
      </template>
      <template v-slot:item.Category="{ item }">
        <div class="stepper-table-content" v-if="item.transactionIn">
          <span class="p-1 mr-1 category-table-content">Money-In </span>
          <v-btn
            icon
            class="icon-color first-blue"
            @click.prevent="printMoneyInFacture(item)"
            :disabled="item.transactionIn && item.transactionIn.status !== 0"
          >
            <v-icon dark>mdi-download</v-icon>
          </v-btn>
        </div>
        <div class="stepper-table-content" v-if="item.transactionP2P">
          <span class="p-1 mr-1 category-table-content">P2P </span>
        </div>
        <div class="stepper-table-content" v-if="item.transactionOut">
          <span class="p-1 mr-1 category-table-content">Money-Out </span>
        </div>
      </template>
      <template v-slot:item.Amount="{ item }">
        <div class="stepper-table-content" v-if="item.transactionIn">
          {{ item.transactionIn.creditAmount / 100 }} €
        </div>
        <div class="stepper-table-content" v-if="item.transactionP2P">
          {{ item.transactionP2P.creditAmount / 100 }} €
        </div>
        <div class="stepper-table-content" v-if="item.transactionOut">
          {{ item.transactionOut.debitAmount / 100 }} €
        </div>
      </template>
      <template v-slot:item.Transmitter="{ item }">
        <div class="stepper-table-content" v-if="item.transactionIn">
          -
        </div>
        <div class="stepper-table-content" v-if="item.transactionP2P">
          {{ item.transactionP2P.senderAccountId }}
        </div>
        <div class="stepper-table-content" v-if="item.transactionOut">
          {{ item.transactionOut.senderAccountId }}
        </div>
      </template>
      <template v-slot:item.Status="{ item }">
        <div v-if="item.transactionIn">
          <span
            class="p-2 status-table-content"
            v-if="item.transactionIn.status === 0"
            >Accepté</span
          >
          <span
            class="p-2 status-table-content waiting "
            v-if="item.transactionIn.status === 4"
            >En atttente</span
          >
          <span
            class="p-2 status-table-content annuler"
            v-if="item.transactionIn.status === 7"
            >Annulé</span
          >
          <span
            class="p-2 status-table-content waiting"
            v-if="item.transactionIn.status === 16"
            >En attente de validation</span
          >
        </div>
        <div v-if="item.transactionP2P">
          <span
            class="p-2 status-table-content"
            v-if="item.transactionP2P.status === 3"
            >Accepté</span
          >
          <span
            class="p-2 status-table-content waiting"
            v-if="item.transactionP2P.status === 4"
            >En atttente</span
          >
          <span
            class="p-2 status-table-content annuler"
            v-if="item.transactionP2P.status === 7"
            >Annulé</span
          >
          <span
            class="p-2 status-table-content waiting"
            v-if="item.transactionP2P.status === 16"
            >En attente de validation</span
          >
        </div>
        <div v-if="item.transactionOut">
          <span
            class="p-2 status-table-content"
            v-if="item.transactionOut.status === 0"
            >Accepté</span
          >
        </div>
      </template>
    </v-data-table>
    <PrintMoneyInFacture ref="moneyInFacture" />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { bus } from '@/main';
import PrintMoneyInFacture from '@/components/User/Kyc/Print_MoneyInFacture.vue';

export default {
  components: {
    PrintMoneyInFacture,
  },

  data: () => ({
    sortBy: 'Date',
    sortDesc: false,
    openDeclineDialog: false,
    openDetailDialog: false,
    details: {},
    allTransactionData: [],
    filterTransaction: '',
    clickAmount: false,
  }),

  created() {
    this.setData();
    this.allTransactionData = this.moneyOutsKyc;
    console.log('this.allTransactionData', this.allTransactionData);
  },

  computed: {
    ...mapGetters('saisons', ['selectedSaison']),
    ...mapState({
      moneyOutsKyc: (state) => state.structure.moneyOuts,
      moneyOutsTab: (state) => state.structure.moneyOutsTab,
    }),

    getAllTransaction() {
      if (!this.moneyOutsKyc) return '';
      switch (this.moneyOutsTab) {
        case 0:
          return this.moneyOutsKyc ? this.moneyOutsKyc : '';
        case 1:
          return this.moneyOutsKyc.filter((data) => data.transactionP2P);
        case 2:
          return this.moneyOutsKyc.filter((data) => data.transactionIn);
        case 3:
          return this.moneyOutsKyc.filter((data) => data.transactionOut);
        default:
          return this.moneyOutsKyc;
      }
    },
  },
  methods: {
    setData() {
      bus.$on('set-filter-date', () => {
        this.setClickDate();
      });
      bus.$on('set-filter-amount', () => {
        this.setClickAmount();
      });
      bus.$on('set-filter-transmitter', () => {
        this.setClickTransmitter();
      });
      bus.$on('set-filter-status', () => {
        this.setClickStatus();
      });
    },

    setClickDate() {
      this.moneyOutsKyc.sort((a, b) => {
        const filterA = {
          ...a.transactionP2P,
          ...a.transactionOut,
          ...a.transactionIn,
        };
        const filterB = {
          ...b.transactionP2P,
          ...b.transactionOut,
          ...b.transactionIn,
        };
        // console.log('aaaaaaa---', a);
        // console.log('bbbbbbb---', b);

        // console.log('filterA---', filterA);
        // console.log('filterB---', filterB);

        let testA = filterA && filterA?.date;
        let testB = filterB && filterB?.date;
        let isDesc = false;
        if (this.sortDesc) {
          return testB - testA;
        } else {
          return testA - testB;
        }
      });

      return (this.sortDesc = !this.sortDesc);
    },

    setClickAmount() {
      this.moneyOutsKyc.sort((a, b) => {
        const filterA = {
          ...a.transactionP2P,
          ...a.transactionOut,
          ...a.transactionIn,
        };
        const filterB = {
          ...b.transactionP2P,
          ...b.transactionOut,
          ...b.transactionIn,
        };
        // console.log('aaaaaaa---', a);
        // console.log('bbbbbbb---', b);

        // console.log('filterA---', filterA);
        // console.log('filterB---', filterB);

        let testA =
          (filterA && filterA?.creditAmount) ||
          (filterA && filterA?.debitAmount);
        let testB =
          (filterB && filterB?.creditAmount) ||
          (filterB && filterB?.debitAmount);
        let isDesc = false;
        if (this.sortDesc) {
          return testB - testA;
        } else {
          return testA - testB;
        }
      });

      return (this.sortDesc = !this.sortDesc);
    },

    setClickTransmitter() {
      this.moneyOutsKyc.sort((a, b) => {
        const filterA = {
          ...a.transactionP2P,
          ...a.transactionOut,
          ...a.transactionIn,
        };
        const filterB = {
          ...b.transactionP2P,
          ...b.transactionOut,
          ...b.transactionIn,
        };

        let testA =
          (filterA && filterA?.senderAccountId) ||
          (filterA && filterA?.receiverAccountId);
        let testB =
          (filterB && filterB?.senderAccountId) ||
          (filterB && filterB?.receiverAccountId);
        let isDesc = false;
        if (this.sortDesc) {
          return testB > testA ? -1 : 1;
        } else {
          return testA > testB ? -1 : 1;
        }
      });

      return (this.sortDesc = !this.sortDesc);
    },
    setClickStatus() {
      this.moneyOutsKyc.sort((a, b) => {
        const filterA = {
          ...a.transactionP2P,
          ...a.transactionOut,
          ...a.transactionIn,
        };
        const filterB = {
          ...b.transactionP2P,
          ...b.transactionOut,
          ...b.transactionIn,
        };

        let testA = filterA && filterA?.status;
        let testB = filterB && filterB?.status;
        if (this.sortDesc) {
          return testB - testA;
        } else {
          return testA - testB;
        }
      });

      return (this.sortDesc = !this.sortDesc);
    },

    customSort(items, index, isDesc) {
      console.log('items', items);
      console.log('index', index);
      console.log('isDesc', isDesc);

      items.sort((a, b) => {
        const filterA = {
          ...a.transactionP2P,
          ...a.transactionOut,
          ...a.transactionIn,
        };
        const filterB = {
          ...b.transactionP2P,
          ...b.transactionOut,
          ...b.transactionIn,
        };
        console.log('aaaaaaa---', a);
        console.log('bbbbbbb---', b);

        console.log('filterA---', filterA);
        console.log('filterB---', filterB);
        if (index[0] == 'Date') {
          let testA = filterA && filterA?.date;
          let testB = filterB && filterB?.date;
          if (!isDesc[0]) {
            return testB - testA;
          } else {
            return testA - testB;
          }
        }

        if (index[0] == 'Amount') {
          let creditA = filterA && filterA?.debitAmount;
          let creditB = filterB && filterB?.debitAmount;
          if (!isDesc[0]) {
            return creditB - creditA;
          } else {
            return creditA - creditB;
          }
        }

        if (index[0] == 'Transmitter') {
          let senderA = filterA && filterA?.senderAccountId;
          console.log('senderA---', senderA);

          let senderB = filterB && filterB?.senderAccountId;
          console.log('senderB---', senderB);

          if (!isDesc[0]) {
            return senderB > senderA ? -1 : 1;
          } else {
            return senderA > senderB ? -1 : 1;
          }
        }

        if (index[0] == 'Status') {
          let statusA = filterA && filterA?.status;
          let statusB = filterB && filterB?.status;
          if (!isDesc[0]) {
            return statusB - statusA;
          } else {
            return statusA - statusB;
          }
        }
      });
      return items;
    },

    async printMoneyInFacture(context) {
      console.log('printMoneyInFacture !!!!!!!!!');
      this.$refs.moneyInFacture.printMoneyInFacture(context);
    },
  },

  watch: {
    moneyOutsKyc: {
      handler() {
        console.log('atooo', this.moneyOutsKyc);
      },
      deep: true,
    },
    moneyOutsTab: {
      handler() {
        console.log('atooo moneyOutsTab', this.moneyOutsTab);
      },
      deep: true,
    },
  },

  filters: {
    formatDate(value) {
      if (!value) return '';
      const milliseconds = value * 1000;
      const dateObject = new Date(milliseconds);
      const frDateFormat = dateObject.toLocaleString('fr-FR');
      return frDateFormat;
    },
  },

  props: {
    headers: {
      type: Array,
    },
    areFiltersOpened: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.bg-blue-mode {
  background: #39558b !important;
}
.filtre-panel {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
  /* identical to box height, or 160% */

  letter-spacing: 1px;
  text-transform: uppercase;

  /* Primary/Dark */
  background: #f8f9fb;
  color: #000000;
}
.filter-btn {
  background: #ffffff !important;
  box-shadow: 2px 4px 8px -2px rgba(8, 43, 110, 0.25);
  border-radius: 0% !important;
  padding: 8px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  /* identical to box height */

  display: flex;
  align-items: center;

  /* Primary/Dark */

  color: #000000;
}
.backgrounded {
  background: #ebeef3;
}
.clear-filter {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  /* identical to box height */
  border: 1px solid rgba(8, 43, 110, 0.25);
  border-radius: 30px;
  display: flex;
  align-items: center;

  /* Primary/Blue */

  color: #082b6e;
}
.stepper-table-head {
  background: #39558b !important;

  .table-content {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #ffffff;
  }
}
.stepper-table-content {
  //styleName: Body/14px Deci;
  font-family: 'Roboto';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  border-top: 0px !important;
  padding-bottom: 0px !important;
}
.status-table-content {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */

  text-align: center;

  color: #00be76;
  background: rgba(0, 190, 118, 0.15);
  border-radius: 6px;
  &.waiting {
    background: orange;
    color: white;
  }
  &.annuler {
    background: rgba(255, 0, 0, 0.15);
    color: #ff0000;
  }
}
.category-table-content {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */

  text-align: center;

  /* Primary/Royal */

  color: #082b6e;
  border: 1px solid rgba(8, 43, 110, 0.25);
  border-radius: 30px;
}
.icon-color {
  &.first-blue {
    color: #1c3d7a !important;
  }
}
.paginateur {
  background: #f8f9fb;
}
.action-paginate {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
  /* identical to box height, or 160% */

  display: flex;
  align-items: center;
  letter-spacing: 1px;
  text-transform: uppercase;

  /* Royal/100 */

  color: #020918;
}
.detail-paginate {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */

  text-align: center;

  /* Primary/Dark */

  color: #000000;
}
</style>
