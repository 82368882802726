<template>
  <div class>
    <v-row no-gutters>
      <SubBar
        :title="subBarTitle"
        route_name="gestion_comptabilite__dashboard"
      />
    </v-row>
    <v-row>
        <v-col>
           <ListFacture 
            :context="{ID_Structure: currentStructureId}"
           />
        </v-col>
    </v-row> 
 
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import SubBar from "@/components/Common/Common__SubBar.vue";
import ListFacture from "@/components/FluxFinancier/FluxFinancier__ListFacture.vue";

export default {
  components: { SubBar, ListFacture },

  computed: {

    ...mapGetters('structure', ['currentStructureId']),

    subBarTitle() {
      return "Gestion des factures";
    },
  },

  methods: {

  },
};
</script>

<style lang="scss" scoped>
.loading {
  background: red;
}
</style>
