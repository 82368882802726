<template>
  <div class="py-10 px-6">
    <ValidateForm :loading="loading">
      <v-row class="mt-4">
        <v-col cols="12" class="pb-0">
          <p class="font-weight-bold">Fiche technique</p>
        </v-col>
        <v-col cols="12" class="pb-0">
          <InputText
            rules="required"
            label="Si cette compétition fait partie d'une coupe, merci d'indiquer son nom dans le champ ci-dessous"
            twoLines
          ></InputText>
          <InputText
            rules="required"
            label="Si la compétition a lieu sur une SAE ou des blocs temporaires, choisir 'SAE temporaire' pour le champ ci-dessous"
            twoLines
          ></InputText>
          <InputText
            rules="required"
            label="Tarifs spectateurs"
            type="number"
            twoLines
          ></InputText>
        </v-col>
      </v-row>

      <v-row class="mt-4">
        <v-col cols="12" class="pb-0">
          <p class="font-weight-bold">Illustration</p>
        </v-col>
        <v-col cols="12" class="pb-0">
          <InputText
            rules="required"
            label="Site web de la compétition (ex : http://www.ffme.fr)"
            type="number"
          ></InputText>
          <InputText
            label="Deuxième site web"
            type="number"
          ></InputText>
        </v-col>
      </v-row>

      <v-row class="mt-4">
        <v-col cols="12" class="pb-0">
          <p class="font-weight-bold">Budget</p>
        </v-col>
        <v-col cols="12" class="pb-0">

        </v-col>
      </v-row>

      <v-row class="mt-4">
        <v-col cols="12" class="pb-0">
          <p class="font-weight-bold">Programme</p>
        </v-col>
        <v-col cols="12" class="pb-0">
          <Textarea
            rules="required"
            label="Description de la compétition"
            twoLines
          ></Textarea>
          <Textarea
            rules="required"
            label="Programme et horaires détaillés"
            twoLines
          ></Textarea>
        </v-col>
      </v-row>

      <v-btn type="submit" color="primary">Terminer</v-btn>
    </ValidateForm>
  </div>
</template>

<script>
import ValidateForm from "@/components/utils/form.vue";
import InputText from "@/components/utils/input.vue";
import Textarea from "@/components/utils/textarea.vue"

export default {
  components: {
    ValidateForm,
    InputText,
    Textarea,
  },

  data: () => ({
    data: {
    },
    loading: false,
  }),

  methods: {
    nextStep() {
      this.$emit("onNextStep");
    },
  },

  props: {
    context: {
      type: Object,
    },
  },
};
</script>
