<template>
  <div>
    <v-row no-gutters>
      <SubBar
        :title="subBarTitle"
        route_name="gestion_comptabilite__dashboard"
      />
    </v-row>
    <v-row class="row">
      <v-col class="w-100 m-4">
        <VerificationKyc />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import SubBar from '@/components/Common/Common__SubBar.vue';
import VerificationKyc from '@/components/User/Kyc/VerificationKyc.vue';

export default {
  data: () => ({
    subBarTitle: 'Gestion de compte lemonway KYC',
  }),

  components: { SubBar, VerificationKyc },
};
</script>

<style lang="scss">
.test__dematerialise {
  padding: 0px !important;
}
</style>
