<template>
    <v-row>
        <v-col v-if="!mounted">
            Chargement...
        </v-col>
        <v-col v-else>
             <v-data-table
                v-model="itemsSelected"
                :headers="headers"
                :fixed-header="true"
                :loading="loading"
                :items="items"
                class="elevation-1 list__table"
            >
             <template v-slot:item.EST_Reglement="{ item }">
                 <v-chip
                 v-if='item.EST_Reglement'
  color="green"
>Facture reglée</v-chip>
<v-chip
    v-else
  color="orange"
>Facture non reglée</v-chip>
            </template>
            <template v-slot:item.actions="{ item }">
                <ActionShowFlux  v-if="actionsContains('show')" :item="item" :context="context"></ActionShowFlux>
            </template>


            </v-data-table>
        </v-col>
    </v-row>

</template>


<script>

import {mapActions} from "vuex" 
export default {

    components: {
        ActionShowFlux: () =>
            import(/* webpackChunkName: "action_showFlux" */ "@/components/FluxFinancier/FluxFinancier__ActionShowFlux.vue"),
        
    },

    data: () => ({
        mounted: false,
        items: [],
        itemsSelected: [],
        headers: [
            // { text: "Structure", value: "NomStructure" },
            { text: "Nom", value: "UTI_Utilisateur.CT_Nom" }, 
            { text: "Prenom", value: "UTI_Utilisateur.CT_Prenom" }, 
            { text: "Flux", value: "NomFluxFinancier" },
            { text: "Saison", value: "FEDE_Saison.NomSaison" },
            { text: "Status", value: "EST_Reglement" },
            // { text: "Numéro de licence", value: "LicenceNumero" },
            // { text: "Date de naissance", value: "DN_DateNaissance" },
            // { text: "Licence type", value: "ID_LicenceType" },
            // // { text: "Saison", value: "ID_LicenceSaison" },
            { text: "Actions", value: "actions" },
        ],
        loading: false,
        tableKey: "",
        actions: 'show'
    }),

    async created() {
        await this.setData()
        this.mounted = true
        return
    },

    methods: {
        ...mapActions('structure', ['getAllFluxFinancierStructure']),

        async setData() {
            let flux = await  this.getAllFluxFinancierStructure(this.context)
            this.items = flux
        
            return
        },

        actionsContains(act) {
         let actions = this.actions.split('|')
         return (actions.find(action => action == act))
      },
    },

    props: {
        context: {
            type: Object
        }
    }

}

</script>