<template>
  <div class="h-100 position-relative">
    <v-row>
      <v-col cols="12">
        <h1 class="stepper-title py-5 px-5">Etape 1 - Vérification KYC</h1>
      </v-col>
    </v-row>
    <v-row class="ml-1">
      <v-col class="stepper-info p-0" cols="12">
        <span class="stepper-info-text d-block p-3">
          <strong>Informations</strong> : La loi oblige les places de marché et
          les plateformes de financement participatif à
          <strong>mettre en place des procédures strictes de KYC</strong> (Know
          Your Customer - Vérification de l'identité des clients). Les lignes
          directrices de l’Autorité de Contrôle Prudentiel et de Résolution
          (ACPR) précisent les modalités de leur mise en œuvre. Les procédures,
          mises en place avant l’entrée en relation d’affaires, conditionnent
          l’ouverture d’un compte utilisateur. Les vérifications préalablement
          effectuées par les opérateurs de place de marché sont en effet
          destinées
          <strong>
            à prévenir le risque de fraude, de blanchiment d’argent et de
            financement d’activités terroristes</strong
          >
          <a href="#">(LCB-FT)</a> .
        </span>
      </v-col>
    </v-row>
    <ValidateForm @validated="submit" :showFieldObl="false">
      <v-row class="mt-5 ml-3 mb-5 pr-3">
        <v-col class="d-flex" cols="12" v-if="isEA">
          <span class="stepper-menu-title">
            Personne morale : Société (Établissement affilié)
          </span>
        </v-col>
        <v-col class="d-flex" cols="12">
          <span class="stepper-menu-subtitle">
            Informations de la structure
          </span>
          <v-divider class="ml-5"></v-divider>
        </v-col>
        <v-col sm="12" lg="6" cols="6">
          <InputText
            label="Nom de la structure"
            v-model="accountInfos.company.name"
            rules="min:0|max:100|required"
            disabled
          ></InputText>
        </v-col>
        <v-col sm="12" lg="6" cols="6"></v-col>
        <v-col sm="12" lg="6" cols="6">
          <InputText
            label="Adresse email"
            required
            v-model="accountInfos.email"
            rules="required|email"
          ></InputText>
        </v-col>
        <v-col sm="12" lg="6" cols="6">
          <InputText
            v-model="accountInfos.phoneNumber"
            rules="required|min:0|max:50"
            label="Téléphone"
          ></InputText>
        </v-col>
        <v-col sm="12" lg="6" cols="6">
          <AddressAutocomplete
            :item="accountInfos.adresse.city"
            rules="required|min:6|max:50"
            label="Adresse"
            :minimum_characters="3"
            ref="adresseAuto"
            @onSelected="adresseSelected"
          ></AddressAutocomplete>
        </v-col>
        <v-col sm="12" lg="6" cols="6">
          <InputText
            v-model="accountInfos.codePostal"
            :key="refreshCodePostal"
            :rules="limit"
            label="Code postal *"
          ></InputText>
        </v-col>
        <v-col sm="12" lg="6" cols="6">
          <InputText
            v-model="accountInfos.ville"
            :key="refreshVille"
            rules="required"
            label="Ville"
          ></InputText>
        </v-col>
        <v-col sm="12" lg="6" cols="6">
          <countriesSelect
            v-model="currentPays"
            label="Pays *"
            @onChange="NationaliteChange"
          ></countriesSelect>
        </v-col>
        <v-col class="d-flex" cols="12">
          <span class="stepper-menu-subtitle">
            Représentant légal
          </span>
          <v-divider class="ml-5"></v-divider>
        </v-col>
        <v-col sm="12" lg="6" cols="6">
          <InputText
            v-model="accountInfos.lastname"
            rules="required|min:2|max:250|regexname|dashCounter"
            label="Nom"
          ></InputText>
        </v-col>
        <v-col sm="12" lg="6" cols="6">
          <InputText
            v-model="accountInfos.firstname"
            rules="required|min:2|max:250|regexname"
            label="Prénom"
          ></InputText>
        </v-col>
        <v-col sm="12" lg="6" cols="6">
          <countriesSelect
            v-model="currentNationalite"
            label="Nationalité *"
          ></countriesSelect>
        </v-col>
        <v-col sm="12" lg="6" cols="6">
          <datePicker
            v-model="birthday"
            rules="date|required"
            label="Date de naissance"
            withIcon="false"
          />
        </v-col>
        <div v-if="isEA">
          <v-row
            class="m-0"
            v-for="(beneficial, i) in accountBeneficialOwner"
            :key="i"
          >
            <v-col class="d-flex" cols="12">
              <span class="stepper-menu-subtitle">
                Bénéficiaire effectif {{ i + 1 }}/4
              </span>
              <v-divider class="ml-5 mr-5"></v-divider>
              <v-icon
                v-if="i === 0 && accountBeneficialOwner.length < 4"
                @click="addBeneficiaire()"
                >mdi-plus</v-icon
              >
              <v-icon
                color="red lighten-2"
                v-else
                @click="removeBeneficiaire(i)"
                >mdi-minus</v-icon
              >
            </v-col>
            <v-col sm="12" lg="6" cols="6">
              <InputText
                v-model="beneficial.lastname"
                rules="required|min:2|max:250|regexname|dashCounter"
                label="Nom"
              ></InputText>
            </v-col>
            <v-col sm="12" lg="6" cols="6">
              <InputText
                v-model="beneficial.firstname"
                rules="required|min:2|max:250|regexname"
                label="Prénom"
              ></InputText>
            </v-col>
            <v-col sm="12" lg="6" cols="6">
              <countriesSelect
                v-model="beneficial.nationality"
                label="Pays"
              ></countriesSelect>
            </v-col>
            <v-col sm="12" lg="6" cols="6">
              <datePicker
                v-model="beneficial.dateOfBirth"
                rules="date|required"
                label="Date de naissance"
                withIcon="false"
              />
            </v-col>
            <v-col sm="12" lg="6" cols="6">
              <countriesSelect
                v-model="beneficial.countryOfBirth"
                label="Pays de naissance"
              ></countriesSelect>
            </v-col>
            <v-col sm="12" lg="6" cols="6">
              <InputText
                v-model="accountInfos.cityOfBirth"
                :key="refreshVille"
                rules="required|min:1|max:60"
                label="Ville de naissance"
              ></InputText>
            </v-col>
          </v-row>
        </div>

        <v-col cols="12" class="d-flex justify-content-end">
          <v-btn
            type="submit"
            class="btn btn-primary stepper-action m-3"
            :loading="loading"
            :disabled="isDisabled"
            >Créer le compte</v-btn
          >
        </v-col>
      </v-row>
    </ValidateForm>
  </div>
</template>

<script>
import InputText from '@/components/utils/input.vue';
import countriesSelect from '@/components/utils/select__countries.vue';
import AddressAutocomplete from '@/components/utils/adresseAutocomplete';
import { mapGetters, mapActions } from 'vuex';
import { sleep } from '@/plugins/utils';
import {
  error_notification,
  success_notification,
} from '@/plugins/notifications';
import ValidateForm from '@/components/utils/form.vue';
import fetch from "node-fetch";

export default {
  components: {
    InputText,
    countriesSelect,
    ValidateForm,
    AddressAutocomplete,
    DatePicker: () =>
      import(
        /* webpackChunkName: "input__datepicker" */ '@/components/utils/datePicker.vue'
      ),
  },

  data() {
    return {
      accountBeneficialOwner: [
        {
          lastname: null,
          firstname: null,
          nationality: null,
          dateOfBirth: null, //required
          cityOfBirth: null, //required
          countryOfBirth: null, //required
        },
      ],
      accountInfos: {
        company: {
          name: '',
          description: '',
        },
        accountId: '',
        email: '',
        firstname: '',
        lastname: '',
        adresse: {
          city: '',
          country: '',
        },
        codePostal: '',
        ville: '',
        birth: {
          date: '',
        },
        nationality: '',
        phoneNumber: '',
        payerOrBeneficiary: '',
      },
      birthday: '',
      haveAccount: null,
      restrictCountry: 'FR',
      refreshCodePostal: 99,
      refreshVille: 0,
      limit: '',
      currentPays: null,
      currentNationalite: {},
      listPays: [],
      currentAddress: '',
      accountCreated: false,
      loading: false,
    };
  },
  // data: () => ({

  // }),

  async created() {
    // this.accountInfos.adresse.city = this.currentUser.ADR_Adresse[0].Adresse1;
    // this.birthday = this.$moment(this.currentUser.DN_DateNaissance).format(
    //   'YYYY-MM-DD'
    // );
    await this.setData();
    console.log('currentStructureId', this.currentStructureId);

    console.log('currentStructure', this.currentStructure);
    console.log('currentUser', this.currentUser);
    console.log('currentStructureType', this.currentStructureType);
    // console.log("getUserPaymentAccount", await this.getUserPaymentAccount());
  },

  computed: {
    ...mapGetters('basics', ['getList']),
    ...mapGetters('structure', [
      'currentStructureId',
      'currentStructure',
      'currentStructureType',
    ]),
    ...mapGetters('user', ['currentUser']),

    isEA() {
      return (
        this.currentStructureType.SlugTypeStructure ==
        'club_etablissement_affilie'
      );
    },

    isDisabled() {
      if (
        this.accountInfos.email &&
        this.accountInfos.phoneNumber &&
        this.accountInfos.codePostal &&
        this.accountInfos.ville &&
        this.currentPays &&
        this.currentNationalite &&
        this.accountInfos.lastname &&
        this.accountInfos.firstname &&
        this.birthday
      ) {
        return false;
      } else {
        return true;
      }
    },

    companyName() {
      if (
        this.currentStructure.STR_Structure.LIST_StructureType
          .SlugTypeStructure == 'club_etablissement_affilie'
      )
        return 'Nom de l’établissement';
      return 'Nom de la structure';
    },
    haveUserAccount() {
      if (this.haveAccount) return true;
      return false;
    },
    haveUserInfos() {
      if (this.currentUser) return true;
      return false;
    },
  },

  methods: {
    ...mapActions('basics', ['getListPays']),
    ...mapActions('utilisateurs', [
      'getUserPaymentAccount',
      'updateUserPaymentAccount',
    ]),

    nextStep() {
      this.$emit('onNextStep');
    },

    addBeneficiaire() {
      const data = {
        lastname: null,
        firstname: null,
        nationality: null,
        dateOfBirth: null,
        countryOfBirth: null,
        cityOfBirth: null,
      };
      this.accountBeneficialOwner.push(data);
    },

    removeBeneficiaire(index) {
      this.accountBeneficialOwner.splice(index, 1);
    },

    async setData() {
      this.listPays = await this.getListPays();

      const getAllPaymentAccount = await this.getUserPaymentAccount();

      if (getAllPaymentAccount) {
        this.haveAccount = getAllPaymentAccount.find(
          (account) => account.ID_Structure == this.currentStructureId
        );
        console.log('this.haveAccount', this.haveAccount);
      }
      this.accountInfos.company.name = this.haveAccount?.Description
        ? this.haveAccount.Description
        : this.currentStructure.STR_Structure.NomStructure;
      this.accountInfos.company.description = this.currentStructure.STR_Structure.NomStructure;
      this.accountInfos.payerOrBeneficiary = 2;
      // this.accountInfos.accountId = this.currentStructureId.toString();
      this.accountInfos.accountId = this.currentStructureId.toString();

      // this.accountInfos.firstname = this.currentUser.CT_Prenom;
      // this.accountInfos.lastname = this.currentUser.CT_Nom;
      // this.accountInfos.email = this.currentUser.CT_Email;
      // // console.log("accountInfos.adresse.city", this.accountInfos.adresse.city);
      // this.accountInfos.codePostal = this.currentUser.ADR_Adresse[0].CodePostal;
      // this.accountInfos.ville = this.currentUser.ADR_Adresse[0].Ville;

      // this.accountInfos.phoneNumber = this.currentUser.CT_TelephoneMobile;

      // Set current pays restriction
      // if (
      //   this.currentUser &&
      //   this.currentUser.ADR_Pays &&
      //   this.$refs.adresseAuto
      // )
      //   this.$refs.adresseAuto.setRestriction(
      //     this.currentUser.ADR_Pays.CodePays
      //   );

      // if (this.currentUser.ADR_Pays) {
      //   this.currentPays = this.currentUser.ADR_Pays;
      // }
      // if (this.currentUser.ID_PaysNationalite) {
      //   this.currentNationalite = this.listPays.find(
      //     (p) => p.id == this.currentUser.ID_PaysNationalite
      //   );
      // } else {
      //   this.currentNationalite = {};
      // }
    },

    onPaysChanged(item) {
      this.currentUser.ID_Pays = item.id;
      this.currentUser.ADR_Pays = item;
      // this.restrictCountry = item.CodePays;
      // this.$refs.adresseAuto.setRestriction(this.restrictCountry);
      this.currentPays = item;
    },

    NationaliteChange(item) {
      this.currentUser.ID_PaysNationalite = item.id;
    },

    async adresseSelected(item) {
      if (typeof item === 'string')
        return (this.accountInfos.adresse.city = item);

      await sleep(10);
      let adresse1 = item[0].long_name + ' ' + item[1].long_name;
      this.$refs.adresseAuto.currentValue = adresse1?.toUpperCase();
      // this.accountInfos.accountId = this.currentUser.id;
      this.accountInfos.adresse.city = adresse1?.toUpperCase();
      this.accountInfos.ville = item[2].long_name?.toUpperCase();
      this.accountInfos.codePostal = item[6]
        ? item[6].long_name?.toUpperCase()
        : '';
      this.refreshCodePostal++;
      this.refreshVille++;
    },

    async createUserAccount() {
      try {
        let payload = {
          NomCompte: this.accountInfos.accountId,
          Description: this.accountInfos.company.description,
          ID_Utilisateur: this.currentUser.id,
          ID_Structure: this.currentStructure.STR_Structure.id,
        };
        const resp = await this.updateUserPaymentAccount({
          userAccount: payload,
        });
        if (resp && resp.data) {
          return { ok: true };
        } else {
          return { ok: false };
        }
      } catch (e) {
        console.log('error===>', e);
      }
    },

    async submit() {
      this.loading = true;
      let pays = [...this.getList.ADR_Pays];

      const country = pays.filter((c) => c.id === this.currentUser.ADR_Pays.id);
      const nationality = pays.filter(
        (c) => c.id === this.currentUser.ID_PaysNationalite
      );
      // console.log("country", country);
      // console.log("nationality", nationality);

      this.accountInfos.adresse.country = country[0].CodePaysISO;
      this.accountInfos.nationality = nationality[0].CodePaysISO;

      this.accountInfos.birth.date = this.$moment(this.birthday).format(
        'YYYY/MM/DD'
      );

      console.log('this.accountInfos', this.accountInfos);

      // await this.createUserAccount();

      try {
          const resp = await this.callCreateAccount();
          console.log('resp callCreateAccount ====>', resp);

          if (resp.ok && this.isEA) {
            await this.createUserAccount();
            await this.postBeneficialOwner();
            this.$emit('onNextStep');
          } else if (resp.ok && !this.isEA) {
            const resp = await this.createUserAccount();
            console.log('resprespresp', resp);
            if (resp.ok) this.$emit('onNextStep');
          }
      } catch (error) {
        console.log('erro r:', error);
      }
    },

    async callCreateAccount() {
      try {
        const fetch = require('node-fetch');
        const headers = {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': 'Content-Type',
        };

        const response = await fetch(
            `${process.env.VUE_APP_FFME_BACK_URL}/api/kyclemonway/createAccount`,
            {
              method: 'POST',
              headers: {
                'Authorization': "Bearer " + localStorage.getItem(process.env.VUE_APP_AUTH_TOKEN_NAME),
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({data : this.accountInfos}),
            }
        );

        const json = await response.json();
        console.log('json response payment====>', json);
        console.log('json code ====>', json.code);

        if (json.legalAccount) {
          // success_notification('Compte KYC créé');
          this.accountCreated = true;
          // this.$emit('onNextStep');
          this.loading = false;
          return { ok: true };
        } else {
          if (
            json.code === 234 &&
            (json.message.includes('ClientLastName') ||
              json.message.includes('ClientFirstName'))
          ) {
            error_notification(
              `Le champ Nom et Prénom doivent contenir plus d'un caractère`
            );
            this.loading = false;
            return { ok: false };
          } else if (
            json.code === 234 &&
            json.message.includes('PhoneNumber')
          ) {
            error_notification(`Le champ Téléphone est au mauvais format`);
            this.loading = false;
            return { ok: false };
          } else if (json.error?.code === 252) {
            error_notification(
              `Veuillez envoyer de vrais noms et prénoms (différents et avec plus d'un caractère)`
            );
            this.loading = false;
            return { ok: false };
          } else if (json.error?.code === 204) {
            error_notification(
              `L'adresse mail saisie est déjà utilisée sur un autre compte KYC. Merci de saisir une autre adresse mail`
            );
            this.loading = false;
            return { ok: false };
          } else if (json.error?.code === 152) {
            error_notification(`L'utilisateur existe déjà`);
            this.loading = false;
            return { ok: false };
          } else {
            error_notification(
              `${json.error?.message ? json.error?.message : json.message}`
            );
            this.loading = false;
            return { ok: false };
          }
        }
      } catch (error) {
        console.log('erro r:', error);
        this.loading = false;
        error_notification(`${error.message}`);
      }
    },

    async postBeneficialOwner() {
      console.log('ALL accountBeneficialOwner ', this.accountBeneficialOwner);
      let pays = [...this.getList.ADR_Pays];
      let data = [];
      this.accountBeneficialOwner.map(async (beneficial) => {
        const nationality = pays.filter((c) => c.id === beneficial.nationality);
        const country = pays.filter((c) => c.id === beneficial.countryOfBirth);
        const birthday = this.$moment(beneficial.dateOfBirth).format(
          'YYYY/MM/DD'
        );

        beneficial.nationality = nationality[0].CodePaysISO;
        beneficial.dateOfBirth = birthday;
        beneficial.countryOfBirth = country[0].CodePaysISO;
        console.log('log beneficial', beneficial);
        await this.dataBeneficialOwner(beneficial);
      });
    },

    //data beneficial Owner
    async dataBeneficialOwner(BeneficialOwner) {
      try {
        const fetch = require('node-fetch');

        const response = await fetch(
            `${process.env.VUE_APP_FFME_BACK_URL}/api/kyclemonway/beneficialOwner`,
            {
              method: 'POST',
              headers: {
                'Authorization': "Bearer " + localStorage.getItem(process.env.VUE_APP_AUTH_TOKEN_NAME),
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({data : BeneficialOwner, accountId: this.currentStructureId}),
            }
        );

        const dataAccountKyc = await response;
        console.log('BeneficialOwner data ====>', dataAccountKyc);
        // if (dataAccountKyc.ok === true) {
        //   await this.callBeneficialOwner();
        // }
        this.loading = false;
      } catch (error) {
        console.log('erro r:', error);
      }
    },

    // async callBeneficialOwner() {
    //   this.loading = true;
    //   try {
    //     const fetch = require('node-fetch');
    //     const headers = {
    //       // 'Content-Type': 'application/json',
    //       'Access-Control-Allow-Origin': '*',
    //       'Access-Control-Allow-Headers': 'Content-Type',
    //     };

    //     const response = await fetch(
    //       `${process.env.VUE_APP_LEMONWAY_API}/payment/ultimateBeneficialOwner/testCode24`,
    //       {
    //         method: 'get',
    //         headers: headers,
    //       }
    //     );
    //     const json = await response.json();
    //     console.log('json response ultimateBeneficialOwner====>', json);
    //     if (json.ultimateBeneficialOwnerId) {
    //       // this.$emit('onNextStep');
    //       this.loading = false;
    //     } else {
    //       error_notification(`Une erreur est survenue`);
    //       this.loading = false;
    //     }
    //   } catch (error) {
    //     console.log('erro r:', error);
    //     // error_notification(`${error.message}`);
    //   }
    // },
  },
};
</script>

<style lang="scss" scoped>
.stepper-title {
  font-family: 'Roboto';
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: -1px;
  text-align: left;
}

.stepper-menu-title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
}

.stepper-menu-subtitle {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
}

.stepper-action {
  //position: absolute;
  //bottom: 0;
  //right: 0;
}

.stepper-info {
  background: rgba(8, 43, 110, 0.1) !important;
  //border: 2px solid #082B6E;
}

.stepper-info-text {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  /* or 143% */

  display: flex;
  align-items: center;

  /* Primary/Royal */

  color: #082b6e;

  border-left: 2px solid #082b6e !important;
}
</style>
