<template>
  <div class="py-10 px-6">
    <ValidateForm :loading="loading">
      <selectInput
        label="Choisir la saison"
        rules="required"
      ></selectInput>
      <selectInput
        label="Choisir la discipline"
        rules="required"
      ></selectInput>
      <RadioGroup
        v-model="data.type"
        :checked="data.type"
        :options="[{ value:true, label:'Promotionnelle'}, {value: false, label: 'Officielle (compte pour le classement national)' }]"
        label="Type de compétition"
      ></RadioGroup>
      <selectInput
        v-if="!data.type"
        label="Compétitions officielles"
        rules="required"
      ></selectInput>
      <selectInput
        v-if="!data.type"
        label="Département"
        rules="required"
      ></selectInput>
      <v-btn type="submit" color="primary">Étape suivante</v-btn>
    </ValidateForm>
  </div>
</template>

<script>
import ValidateForm from "@/components/utils/form.vue";
import selectInput from "@/components/utils/select.vue";
import RadioGroup from "@/components/utils/radioGroup.vue";

export default {
  components: {
    ValidateForm,
    selectInput,
    RadioGroup,
  },

  data: () => ({
    data: {
      type: true,
    },
    loading: false,
  }),

  methods: {
    nextStep() {
      this.$emit("onNextStep");
    },
  },

  props: {
    context: {
      type: Object,
    },
  },
};
</script>
