<template>
  <div class="py-10 px-6">
    <ValidateForm :loading="loading">
      <selectInput
        label="Discipline"
        disabled
      ></selectInput>
      <InputText
        rules="required|min:2|max:120"
        label="Nom de la compétition"
      ></InputText>
      <InputText
        rules="required|min:2|max:20"
        label="Code compétition"
      ></InputText>
      <DatePicker
        rules="required"
        label="Date de début"
      ></DatePicker>
      <DatePicker
        rules="required"
        label="Date de fin"
      ></DatePicker>
      <selectInput
        label="Région"
        rules="required"
      ></selectInput>
      <selectInput
        label="Département"
        rules="required"
      ></selectInput>
      <selectInput
        rules="required"
        label="Structure responsable"
      ></selectInput>
      <selectInput
        rules="required"
        label="Commune"
      ></selectInput>
      <selectInput
        rules="required"
        label="Lieu(x)"
      ></selectInput>
      <DatePicker
        rules="required"
        label="Date limite d'inscription"
      ></DatePicker>
      <selectInput
        rules="required"
        label="Heure de début de l'accueil"
      ></selectInput>
      <selectInput
        rules="required"
        label="Heure de fin d ela compétition"
      ></selectInput>
      <RadioGroup
        :options="[{ value:true, label:'Oui'}, {value: false, label: 'Non' }]"
        label="Cette compétition est un OPEN ?"
      ></RadioGroup>
      <RadioGroup
        :options="[{ value:true, label:'Oui'}, {value: false, label: 'Non' }]"
        label="Cette compétition accueille des concurrents sport adapté ?"
      ></RadioGroup>

      <v-btn type="submit" color="primary">Étape suivante</v-btn>
    </ValidateForm>
  </div>
</template>

<script>
import ValidateForm from "@/components/utils/form.vue";
import selectInput from "@/components/utils/select.vue";
import RadioGroup from "@/components/utils/radioGroup.vue";
import InputText from "@/components/utils/input.vue";
// import DatePicker from "@/components/utils/datePicker.vue";

export default {
  components: {
    ValidateForm,
    selectInput,
    RadioGroup,
    InputText,
    DatePicker: () => import( /* webpackChunkName: "input__datepicker" */ "@/components/utils/datePicker.vue"),
  },

  data: () => ({
    data: {
    },
    loading: false,
  }),

  methods: {
    nextStep() {
      this.$emit("onNextStep");
    },
  },

  props: {
    context: {
      type: Object,
    },
  },
};
</script>
