<template>
  <div class>
    <v-row no-gutters>
      <SubBar
        :title="subBarTitle"
        route_name="gestion_des_competitions__dashboard"
      />
    </v-row>

    <SaisirUneCompetition
      :context="{
        ID_Structure: currentStructureId,
      }"
    />
  </div>
</template>

<script>
import SubBar from "@/components/Common/Common__SubBar.vue";
import SaisirUneCompetition from "@/components/Competitions/Competitions__SaisirUneCompetition.vue";
import { mapGetters } from "vuex";

export default {
  name: "GestionDesCompetitions__SaisirUneCompetition",

  components: { SubBar, SaisirUneCompetition },

  data: () => ({
    subBarTitle: "Saisir une compétition",
  }),

  computed: {
    ...mapGetters("structure", ["currentStructureId"]),
  },
};
</script>
