<template>
  <div>
    <v-row no-gutters>
      <SubBar
        :title="subBarTitle"
        route_name="gestion_comptabilite__dashboard"
      />
    </v-row>

    <v-row no-gutters class="informationsdemonclub__layout">
      <v-col cols="12" color="blue" sm="4" lg="3">
        <v-row class="informationsdemonclub__sidebar block" no-gutters>
          <v-col cols="12" class="informationsdemonclub__sidebar-navigation">
            <Navigation></Navigation>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12" sm="8" lg="9" class="informationsdemonclub__content">
        <v-row
          class="
            informationsdemonclub__content-content-view
            test__dematerialise
            h-100
          "
          no-gutters
        >
          <transition name="fade" mode="out-in">
            <router-view></router-view>
          </transition>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import SubBar from "@/components/Common/Common__SubBar.vue";
import Navigation from "@/components/GestionsDesFinances/GestionLemonwayKyc_Navigation.vue";

export default {
  data: () => ({
    subBarTitle: "Gestion de compte lemonway KYC",
  }),

  components: { SubBar, Navigation },
};
</script>

<style lang="scss">
.test__dematerialise {
  padding: 0px !important;
}
</style>
