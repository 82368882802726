<template>
  <v-row id="print-depense" ref="print-depense" class="depense-detail">
    <v-col
      cols="12"
      class="position-relative d-flex justify-content-center"
      id="page-0"
      ref="page-0"
    >
      <p class="text-right w-75 position-absolute">Page 1/3</p>
      <img class="habillage" src="~@/assets/FFME_EN_TETE.jpg" alt="" />
      <v-row class="w-100 position-absolute" style="top: 0; padding: 135px;">
        <v-col class="h-75" cols="8"> </v-col>
        <v-col v-if="dataItems[0]" class="d-block" cols="4">
          <h1 class="mb-5">Paris, le {{ $moment().format("DD/MM/YYYY") }}</h1>
          <p class="adresses">
            {{
              dataItems[0].STR_Structure &&
              dataItems[0].STR_Structure.NomStructure
            }}
          </p>
          <p class="adresses">
            {{
              dataItems[0].STR_Structure &&
              dataItems[0].STR_Structure.ADR_Adresse &&
              dataItems[0].STR_Structure.ADR_Adresse.Adresse1
            }}
          </p>
          <p class="adresses">
            {{
              dataItems[0].STR_Structure &&
              dataItems[0].STR_Structure.ADR_Adresse &&
              dataItems[0].STR_Structure.ADR_Adresse.CodePostal
            }}
            {{
              dataItems[0].STR_Structure &&
              dataItems[0].STR_Structure.ADR_Adresse &&
              dataItems[0].STR_Structure.ADR_Adresse.Ville
            }}
          </p>
        </v-col>
        <v-col
          class="d-block ml-5 pl-5 pt-5"
          style="margin-top: 20vh;"
          cols="12"
        >
          <p class="mt-5">Votre numéro d'affiliation : {{ structureCode }}</p>
          <p>Période concernée : {{ print.period }}</p>
        </v-col>
        <v-col cols="12" class="d-flex justify-content-center">
          <p
            class="py-3 mb-0 text-center w-100 bg-primary text-light font-weight-bold"
          >
            DETAIL DES DEPENSES
          </p>
        </v-col>
        <v-col cols="12" class="d-block">
          <p>Monsieur le président,</p>
          <p>
            Veuillez trouver le récapitulatif de vos dépenses pour la période
            {{ print.period }} ci-dessous et le détail par nature ci-après.
          </p>
        </v-col>
        <v-col cols="12" class="d-flex justify-content-center" style="">
          <table class="table-details w-75">
            <tr>
              <th>Nature des opérations</th>
              <th>Détail des dépenses</th>
            </tr>
            <tr>
              <td>Licence</td>
              <td>
                {{ print.licenceAmount.debit + print.licenceAmount.credit }} €
              </td>
            </tr>
            <tr>
              <td>Cotisation CT/Ligues</td>
              <td>
                {{ print.cotisationAmout.debit + print.cotisationAmout.credit }}
                €
              </td>
            </tr>
            <tr>
              <td>Administratif</td>
              <td>
                {{
                  print.administratifAmount.debit +
                  print.administratifAmount.credit
                }}
                €
              </td>
            </tr>
            <!-- <tr>
              <td>Boutique</td>
              <td>
                {{ print.boutiqueAmount.debit + print.boutiqueAmount.credit }}
                €
              </td>
            </tr> -->
            <tr>
              <td>Formation</td>
              <td>
                {{ print.formationAmout.debit + print.formationAmout.credit }}
                €
              </td>
            </tr>
            <tr>
              <td class="">
                <p
                  class="d-flex justify-content-center text-transform-uppercase font-weight-bold text-center"
                  style="padding-top: 13px;"
                >
                  TOTAL {{ print.period }}
                </p>
              </td>
              <td style="font-weight: bold;">{{ total }} €</td>
            </tr>
          </table>
        </v-col>
        <v-col class="d-block" cols="12">
          <p>
            Si vous avez opté pour le prélèvement mensuel, merci de veillez à ce
            que votre compte courant dispose bien de ces disponibilités à la
            date de(s) prélèvement(s) prévu(s).
          </p>
          <p>
            Dans le cas contraire, merci de vous acquitter de ces sommes avant
            le 10 de ce mois par chèque libellé à l'ordre de la FFME ou par
            virement sur notre compte suivant :
          </p>
        </v-col>
        <v-col cols="12">
          <img class="ml-5" src="~@/assets/conditions.png" alt="" />
        </v-col>
        <v-col cols="12">
          <p>
            Veuillez accepter, Monsieur le président, nos sincères salutations.
          </p>
          <p style="font-weight: bold;">
            Service vie territoriale
          </p>
        </v-col>
      </v-row>
    </v-col>
    <v-col
      class="position-relative d-flex justify-content-center"
      cols="12"
      id="page-1"
      ref="page-1"
    >
      <p class="text-right w-75 position-absolute">Page 2/3</p>
      <v-row
        class="pt-7"
        style="top: 0; padding-left: 135px; padding-right: 135px;"
      >
        <v-col cols="12">
          <p class="text-light py-3 bg-primary text-center font-weight-bold">
            LICENCE - Détail des opérations
          </p>
        </v-col>
        <v-col cols="12" class="d-flex justify-content-center">
          <table class="table-details w-100">
            <tr>
              <th>DATE</th>
              <th>LIBELLE</th>
              <th>DEBIT</th>
              <th>CREDIT</th>
              <th>A PAYER</th>
            </tr>
            <tr v-for="(item, i) in licence" :key="i">
              <td>{{ $moment(item.Z_DateCreation).format("DD/MM/YYYY") }}</td>
              <td>{{ item.NomFluxFinancier }}</td>
              <td>{{ !item.EST_Debit ? item.MontantHT : "" }}</td>
              <td>{{ item.EST_Debit ? item.MontantHT : "" }}</td>
              <td></td>
            </tr>
            <tr>
              <td class="text-transform-uppercase font-weight-bold">
                LICENCE - TOTAL A PAYER
              </td>
              <td style="font-weight: bold;"></td>
              <td style="font-weight: bold;">
                {{ print.licenceAmount.debit }} €
              </td>
              <td style="font-weight: bold;">
                {{ print.licenceAmount.credit }} €
              </td>
              <td style="font-weight: bold;">
                {{ print.licenceAmount.debit - print.licenceAmount.credit }} €
              </td>
            </tr>
          </table>
        </v-col>
        <v-col cols="12" class="border-bottom">
          <p>
            Pour toute question concernant les licences, merci d'envoyer un
            courriel à <a href="licence@ffme.fr">licence@ffme.fr</a>
          </p>
        </v-col>
        <v-col cols="12">
          <p class="text-light py-3 bg-primary text-center font-weight-bold">
            COTISATION CT/Ligues - Détail des opérations
          </p>
        </v-col>
        <v-col cols="12" class="d-flex justify-content-center">
          <table class="table-details w-100">
            <tr>
              <th>DATE</th>
              <th>LIBELLE</th>
              <th>DEBIT</th>
              <th>CREDIT</th>
              <th>A PAYER</th>
            </tr>
            <tr v-for="(item, i) in cotisation" :key="i">
              <td>{{ $moment(item.Z_DateCreation).format("DD/MM/YYYY") }}</td>
              <td>{{ item.NomFluxFinancier }}</td>
              <td>{{ !item.EST_Debit ? item.MontantHT : "" }}</td>
              <td>{{ item.EST_Debit ? item.MontantHT : "" }}</td>
              <td></td>
            </tr>
            <tr>
              <td
                class="text-transform-uppercase font-weight-bold font-weight-bold"
              >
                COTISATION CT Ligues - TOTAL A PAYER
              </td>
              <td style="font-weight: bold;"></td>
              <td style="font-weight: bold;">
                {{ print.cotisationAmout && print.cotisationAmout.debit }} €
              </td>
              <td style="font-weight: bold;">
                {{ print.cotisationAmout && print.cotisationAmout.credit }} €
              </td>
              <td style="font-weight: bold;">
                {{
                  print.cotisationAmout &&
                  print.cotisationAmout.debit - print.cotisationAmout.credit
                }}
                €
              </td>
            </tr>
          </table>
        </v-col>
        <v-col cols="12" class="border-bottom">
          <p>
            Pour toute question concernant les cotisations, merci d'envoyer un
            courriel à
            <a href="relations-cd-cr@ffme.fr">relations-cd-cr@ffme.fr</a>
          </p>
        </v-col>
      </v-row>
    </v-col>
    <v-col
      id="page-2"
      class="position-relative d-flex justify-content-center"
      ref="page-2"
    >
      <p class="text-right w-75 position-absolute">Page 3/3</p>
      <v-row
        class="pt-7"
        style="top: 0; padding-left: 135px; padding-right: 135px;"
      >
        <v-col cols="12">
          <p class="text-light py-3 bg-primary text-center font-weight-bold">
            FORMATION - Détail des opérations
          </p>
        </v-col>
        <v-col cols="12" class="d-flex justify-content-center">
          <table class="table-details w-100">
            <tr>
              <th>DATE</th>
              <th>LIBELLE</th>
              <th>DEBIT</th>
              <th>CREDIT</th>
              <th>A PAYER</th>
            </tr>
            <tr v-for="(item, i) in formation" :key="i">
              <td>{{ $moment(item.Z_DateCreation).format("DD/MM/YYYY") }}</td>
              <td>{{ item.NomFluxFinancier }}</td>
              <td>{{ !item.EST_Debit ? item.MontantHT : "" }}</td>
              <td>{{ item.EST_Debit ? item.MontantHT : "" }}</td>
              <td></td>
            </tr>
            <tr>
              <td class="text-transform-uppercase font-weight-bold">
                FORMATION - TOTAL A PAYER
              </td>
              <td style="font-weight: bold;"></td>
              <td style="font-weight: bold;">
                {{ print.formationAmout && print.formationAmout.debit }} €
              </td>
              <td style="font-weight: bold;">
                {{ print.formationAmout && print.formationAmout.credit }} €
              </td>
              <td style="font-weight: bold;">
                {{
                  print.formationAmout &&
                  print.formationAmout.debit - print.formationAmout.credit
                }}
                €
              </td>
            </tr>
          </table>
        </v-col>
        <v-col cols="12" class="border-bottom">
          <p>
            Pour toute question concernant la formation, merci d'envoyer un
            courriel à <a href="formation@ffme.fr">formation@ffme.fr</a>
          </p>
        </v-col>
        <v-col cols="12">
          <p class="text-light py-3 bg-primary text-center font-weight-bold">
            ADMINISTRATIF - Détail des opérations
          </p>
        </v-col>
        <v-col cols="12" class="d-flex justify-content-center">
          <table class="table-details w-100">
            <tr>
              <th>DATE</th>
              <th>LIBELLE</th>
              <th>DEBIT</th>
              <th>CREDIT</th>
              <th>A PAYER</th>
            </tr>
            <tr v-for="(item, i) in administratif" :key="i">
              <td>{{ $moment(item.Z_DateCreation).format("DD/MM/YYYY") }}</td>
              <td>{{ item.NomFluxFinancier }}</td>
              <td v-if="item.EST_Debit === false">
                {{ !item.EST_Debit ? item.MontantHT : "" }}
              </td>
              <td v-if="item.EST_Debit === true">
                {{ item.EST_Debit ? item.MontantHT : "" }}
              </td>
              <td></td>
            </tr>
            <tr>
              <td class="text-transform-uppercase font-weight-bold">
                ADMINISTRATIF - TOTAL A PAYER
              </td>
              <td style="font-weight: bold;"></td>
              <td style="font-weight: bold;">
                {{
                  print.administratifAmount && print.administratifAmount.debit
                }}
                €
              </td>
              <td style="font-weight: bold;">
                {{
                  print.administratifAmount && print.administratifAmount.credit
                }}
                €
              </td>
              <td style="font-weight: bold;">
                {{
                  print.administratifAmount &&
                  print.administratifAmount.debit -
                    print.administratifAmount.credit
                }}
                €
              </td>
            </tr>
          </table>
        </v-col>
        <v-col cols="12" class="border-bottom">
          <p>
            Pour toute question concernant l'administratif, merci d'envoyer un
            courriel à <a href="club@ffme.fr">club@ffme.fr</a>
          </p>
        </v-col>
        <!-- <v-col cols="12">
          <p class="text-light py-3 bg-primary text-center">
            BOUTIQUE - Détail des opérations
          </p>
        </v-col>
        <v-col cols="12" class="d-flex justify-content-center">
          <table class="table-details w-100">
            <tr>
              <th>DATE</th>
              <th>LIBELLE</th>
              <th>DEBIT</th>
              <th>CREDIT</th>
              <th>A PAYER</th>
            </tr>
            <tr v-for="(item, i) in boutique" :key="i">
              <td>{{ $moment(item.Z_DateCreation).format("DD/MM/YYYY") }}</td>
              <td>{{ item.NomFluxFinancier }}</td>
              <td>{{ !item.EST_Debit ? item.MontantHT : "" }}</td>
              <td>{{ item.EST_Debit ? item.MontantHT : "" }}</td>
              <td></td>
            </tr>
            <tr>
              <td class="text-transform-uppercase font-weight-bold">
                BOUTIQUE - TOTAL A PAYER
              </td>
              <td style="font-weight: bold"></td>
              <td style="font-weight: bold">
                {{ print.boutiqueAmount && print.boutiqueAmount.debit }} €
              </td>
              <td style="font-weight: bold">
                {{ print.boutiqueAmount && print.boutiqueAmount.credit }} €
              </td>
              <td style="font-weight: bold">
                {{
                  print.boutiqueAmount &&
                    print.boutiqueAmount.debit - print.boutiqueAmount.credit
                }}
                €
              </td>
            </tr>
          </table>
        </v-col>
        <v-col cols="12" class="border-bottom">
          <p>
            Pour toute question concernant la boutique, merci d'envoyer un
            courriel à <a href="boutique@ffme.fr">boutique@ffme.fr</a>
          </p>
        </v-col> -->
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
export default {
  data: () => ({
    data: [],
    dataItems: [],
    structureCode: "",
    licence: [],
    cotisation: [],
    formation: [],
    boutique: [],
    administratif: [],
    total: 0,
  }),

  props: {
    print: {
      type: Object,
    },
  },

  methods: {
    printDepense(item) {
      this.data = item;
      this.dataItems = this.data[0];
      this.structureCode = this.dataItems[0].STR_Structure.ID_CodeFederal;
      this.licence = this.print.licence && this.print.licence[0];
      this.cotisation = this.print.cotisation && this.print.cotisation[0];
      this.formation = this.print.formation && this.print.formation[0];
      this.boutique = this.print.boutique && this.print.boutique[0];
      this.administratif =
        this.print.administratif && this.print.administratif[0];

      this.total =
        this.print.licenceAmount.debit +
        this.print.licenceAmount.credit +
        this.print.cotisationAmout.debit +
        this.print.cotisationAmout.credit +
        this.print.formationAmout.debit +
        this.print.formationAmout.credit +
        this.print.boutiqueAmount.debit +
        this.print.boutiqueAmount.credit +
        this.print.administratifAmount.debit +
        this.print.administratifAmount.credit;

      let tableau = [];
      const name = "Détails dépenses ";

      this.$nextTick(async () => {
        this.$refs["print-depense"].style.display = "block";

        for (let i = 0; i < 3; i++) {
          let source = document.querySelector("#page-" + i);
          const canvas = await html2canvas(source, {
            letterRendering: 1,
            scale: 2,
            logging: true,
            useCORS: true,
          });
          const imgData = canvas.toDataURL("image/jpeg");
          tableau.push(imgData);
        }

        const pdf = new jsPDF({
          orientation: "portrait",
        });
        for (let i = 0; i < tableau.length; i++) {
          var position = 0;
          const imgProps = pdf.getImageProperties(tableau[i]);
          const pdfWidth = pdf.internal.pageSize.getWidth();
          const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
          pdf.addImage(tableau[i], "JPEG", 0, position, pdfWidth, pdfHeight);
          if (i < tableau.length - 1) {
            pdf.addPage();
          }
        }
        pdf.save(name);
        this.$refs["print-depense"].style.display = "none";
      });
    },
  },
};
</script>

<style lang="scss">
.depense-detail {
  font-family: Roboto !important;
  font-style: normal !important;
  font-size: 16px !important;
}
h1 {
  display: flex;
  // justify-content: center;
  font-size: 16px;
  font-family: "Roboto";
  font-weight: bold;
}
.adresses {
  display: flex;
  // justify-content: center;
  // padding-top: 20px;
  font-size: 16px;
  font-family: "Roboto";
}
#print-depense {
  //width: 1000px;
  position: absolute;
  z-index: -3;
  background: #fff;
  top: 0;
  left: 0;
  display: none;
  .container {
    position: relative;
    width: 100%;
    height: 100%;
  }
  .habillage {
    width: 100%;
    z-index: -1;
  }
  .header {
    position: absolute;
    width: 100%;
    left: 50px;
    top: 10vh;

    .header-footer {
      margin-top: 10vh;
      p {
        font-family: "Roboto";
        font-size: 16px;
        font-weight: bold;
      }
    }
  }
  .title_tab {
    position: absolute;
    width: 100%;
    height: 30px;
    background-color: #036ab5;
    p {
      font-family: "Roboto";
      font-size: 22px;
      color: white;
      display: flex;
      justify-content: center;
    }
  }
  .text_recapitulatif {
    position: absolute;
    top: 55vh;
    padding-left: 20px;
    padding-right: 20px;
    font-family: "Roboto";
    font-size: 16px;
  }
  .table-details {
    th,
    td {
      border: 1px solid black;
      border-collapse: collapse;
      // width: 500px;
      font-size: 16px;
      margin-left: 10px;
      text-align: center;
      font-family: "Roboto" !important;
      font-style: normal !important;
      font-size: 16px !important;
    }
  }
  .text_condition {
    top: 85vh;
    padding-left: 20px;
    padding-right: 20px;
    font-family: "Roboto";
    font-size: 16px;
  }
  .conditions {
    display: flex;
    justify-content: center;
    top: 85vh;
    left: 20px;
    right: 20px;
    img {
      width: 35vh;
    }
  }
  .text_condition_footer {
    top: 90vh;
    padding-left: 20px;
    padding-right: 20px;
    font-family: "Roboto";
    font-size: 16px;
  }
  .question_indication {
    top: 70vh;
    padding-left: 20px;
    padding-right: 20px;
    font-family: "Roboto";
    font-size: 16px;
  }
  .title_tabs {
    width: 100%;
    height: 30px;
    background-color: #036ab5;
    padding-top: 10vh;
    p {
      font-family: "Roboto";
      font-size: 22px;
      color: white;
      display: flex;
      justify-content: center;
    }
  }
}
</style>
