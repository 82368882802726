<template>
  <v-row class="m-0">
    <v-tabs v-model="tab">
      <v-tab v-for="item in items" :key="item" class="main-place-marche-tab">{{
        item
      }}</v-tab>
    </v-tabs>
    <v-tabs-items class="w-100" v-model="tab">
      <v-tab-item>
        <v-card class="p-0" flat>
          <v-card-text class="p-0">
            <v-row class="m-0">
              <v-col
                class="d-flex justify-content-center align-items-center"
                cols="12"
                v-if="currentRib"
              >
                <v-row class="m-0 d-flex justify-content-center">
                  <v-col class="ml-3 mt-3 d-flex rib" lg="5" md="5" sm="11">
                    <div class="avatar-bank mr-3">
                      <v-icon class="icon-color first-blue">mdi-bank</v-icon>
                    </div>
                    <div class="rib-bank">
                      <p class="mb-0">{{ currentRib.Domiciliation }}</p>
                      <!-- <span class="mb-3"
                        >Esplanade Hôtel de Ville, 74000 Annecy</span
                      > -->
                      <p class="coordonne mb-0">
                        Iban &nbsp;<span class="coordonne">
                          {{ currentRib.FIN_IbanNumero }}
                        </span>
                      </p>
                      <p class="coordonne">
                        BIC &nbsp;<span class="coordonne">
                          {{ currentRib.FIN_BicNumero }}
                        </span>
                      </p>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12">
                <v-row>
                  <v-col class="d-flex" cols="12">
                    <span class="mr-5 title-place-marche">Action en cours</span>
                    <v-divider class="ml-5"></v-divider>
                  </v-col>
                  <v-col cols="12" class="d-block">
                    <v-row class="m-0 py-1">
                      <v-col cols="3" class="p-0">
                        <span class="coordonne">Description de l’action</span>
                      </v-col>
                      <v-col cols="9" class="py-0">
                        <p class="phrase d-flex mb-0">
                          Transférer les fonds de votre portefeuille LemonWay
                          vers le compte bancaire de votre structure.
                        </p>
                      </v-col>
                    </v-row>
                    <v-row class="m-0 py-1">
                      <v-col cols="3" class="p-0">
                        <span class="mr-5 coordonne">Commission</span>
                      </v-col>
                      <v-col cols="9" class="py-0">
                        <p class="phrase">
                          <strong>0.10 €</strong>&nbsp; par transfert. Ces fonds
                          seront crédités par la FFME via votre compte LemonWay.
                        </p>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="d-flex" cols="12">
                    <span class="mr-5 title-place-marche"
                      >Description de la transaction</span
                    >
                    <v-divider class="ml-5"></v-divider>
                  </v-col>
                  <v-col cols="12" class="d-block">
                    <v-row class="m-0 py-1">
                      <v-col cols="3" class="p-0 mt-5">
                        <span class="coordonne">Motif du transfert *</span>
                      </v-col>
                      <v-col cols="9" class="py-0">
                        <InputText
                          v-model="dataTransfert.transfertComment"
                          rules="required|min:1|max:60"
                        ></InputText>
                      </v-col>
                    </v-row>
                    <v-row class="m-0 py-1">
                      <v-col cols="3" class="p-0">
                        <span class="mr-5 mt-3 coordonne"
                          >Montant du transfert *</span
                        >
                      </v-col>
                      <v-col cols="9" class="py-0">
                        <input
                          type="number"
                          class="form-control"
                          v-model="dataTransfert.transfertAmount"
                        />
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row class="m-0 p-1 backgrounded">
              <p class="mb-0 section-place-marche-prix">
                <strong>Information :</strong>
                &nbsp;Le transfert sera effectif sur votre compte entre 1 et 3
                jours.
              </p>
            </v-row>
            <v-row class="m-0">
              <v-col cols="12" class="d-flex mt-5 mb-5">
                <v-btn
                  type="submit"
                  class="btn btn-primary"
                  @click="confirmModal(dataTransfert)"
                  :disabled="isDisabled"
                  >Procéder au transfert</v-btn
                >
                <span class="clear-filter p-1 px-3 ml-5" @click="close"
                  >Annuler l'action</span
                >
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>

    <pmTransfertModal
      :openModal="openModal"
      :data="dataTransfert"
      @onSend="sendMoneyOuts()"
      :loading="loading"
    />
  </v-row>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import pmTransfertModal from '@/components/User/Kyc/Pm_Transfert_Modal.vue';
import InputText from '@/components/utils/input.vue';
import {
  error_notification,
  success_notification,
} from '@/plugins/notifications';
import { bus } from '@/main';
import fetch from "node-fetch";

export default {
  components: {
    pmTransfertModal,
    InputText,
  },
  data() {
    return {
      items: ['Money-Out'],
      loading: false,
      tab: null,
      openModal: false,
      currentRib: null,
      transfertAmount: null,
      dataTransfert: {},
    };
  },

  async created() {
    await this.setData();
  },

  computed: {
    ...mapGetters('structure', ['currentStructureId', 'currentStructure']),
    ...mapState({
      moneyOutsIban: (state) => state.structure.moneyOutsIban,
    }),

    isDisabled() {
      if (
        this.dataTransfert.transfertAmount &&
        this.dataTransfert.transfertComment
      )
        return false;
      return true;
    },
  },

  methods: {
    ...mapActions('structure', ['getRibByStructure']),

    async setData() {
      console.log('moneyOutsIban', this.moneyOutsIban)
      const structureRib = await this.getRibByStructure({
        ID_Structure: this.currentStructureId, //mila ovaina currentStructureId
      });
      this.currentRib = structureRib[0];
      console.log('this.currentRib', this.currentRib);
    },

    exitViewer() {
      this.$emit('onExitViewer');
    },

    close() {
      this.$store.commit('structure/SET_OPEN_MONEY_OUTS', false);
    },

    confirmModal(dataTransfert) {
      this.openModal = true;
      this.dataTransfert = dataTransfert;
    },

    async sendMoneyOuts() {
      this.loading = true;
      let payload = {
        accountId: this.currentStructureId, //mila ovaina
        ibanId: this.moneyOutsIban?.id,
        totalAmount: this.dataTransfert?.transfertAmount * 100,
        comment: this.dataTransfert?.transfertComment,
      };
      console.log('payload moneyouts send', payload);
      try {
        const fetch = require('node-fetch');

        const response = await fetch(
            `${process.env.VUE_APP_FFME_BACK_URL}/api/kyclemonway/moneyTransfert`,
            {
              method: 'POST',
              headers: {
                'Authorization': "Bearer " + localStorage.getItem(process.env.VUE_APP_AUTH_TOKEN_NAME),
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(payload),
            }
        );

        const json = await response.json();
        console.log('json money outs ', json);
        if (json.transaction) {
          this.loading = false;
          this.openModal = false;
          success_notification('Transfère effectué !');
          bus.$emit('reload-money-outs');
          this.$store.commit('structure/SET_OPEN_MONEY_OUTS', false);
          return { ok: true };
        } else if (json.error.code === 110) {
          this.loading = false;
          this.openModal = false;
          error_notification(
            `Montant saisi supérieur au solde de votre compte`
          );
          return { ok: false };
        }
        // error_notification(`Veuillez réessayer plus tard`);
      } catch (error) {
        console.log('erro r:', error);
      }
    },
  },
};
</script>

<style lang="scss">
.clear-filter {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 12px;
  /* identical to box height */
  border: 1px solid rgba(8, 43, 110, 0.25);
  border-radius: 30px;
  display: flex;
  align-items: center;
  cursor: pointer;
  /* Primary/Blue */

  color: #082b6e;
}
.backgrounded {
  background: rgba(8, 43, 110, 0.1);
  border-left: 2px solid #082b6e;
}
.main-place-marche-tab {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */

  display: flex;
  align-items: center;

  /* Primary/Royal */
  text-transform: none !important;
  color: #082b6e;
}
.left-bar {
  background: #e7effd;
}
.icon-color {
  &.first-blue {
    color: #1c3d7a !important;
  }
}
.title-place-marche {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  /* or 143% */

  display: flex;
  align-items: center;

  /* Grey/100 */

  color: #1c3d7a;
}
.avatar-bank {
  width: 40px;
  height: 40px;
  background: #ffffff;
  box-shadow: 2px 4px 8px -2px rgba(8, 43, 110, 0.25);
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.coordonne {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 10px !important;
  line-height: 16px;
  /* identical to box height, or 160% */

  display: flex;
  align-items: center;
  letter-spacing: 1px;
  text-transform: uppercase;

  /* Primary/Dark */

  color: #000000 !important;
}
.phrase {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  align-items: center;

  /* Grey/70 */

  color: #000000;
}
.rib-bank {
  p {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */

    display: flex;
    align-items: center;

    /* Grey/70 */

    color: #90a1be;
  }

  span {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    /* identical to box height, or 133% */

    display: flex;
    align-items: center;

    /* Grey/50 */

    color: #bac4d7;
    &.coordonne {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 10px !important;
      line-height: 16px;
      /* identical to box height, or 160% */

      display: flex;
      align-items: center;
      letter-spacing: 1px;
      text-transform: uppercase;

      /* Primary/Dark */

      color: #000000 !important;
    }
  }
}
.section-place-marche {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}
.section-place-marche-prix {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 32px;
  /* identical to box height, or 133% */

  display: flex;
  align-items: center;
  letter-spacing: -0.5px;

  /* Primary/Royal */

  color: #082b6e;
}
.rib {
  /* Primary/White */
  background: #ffffff;
  /* Shadow/Royal */
  box-shadow: 2px 4px 8px -2px rgba(8, 43, 110, 0.25);
  border-radius: 4px;
}
</style>
