<template>
  <div>
    <v-stepper v-model="etape" alt-labels>
      <v-stepper-header>
        <v-stepper-step :complete="etape > 1" step="1">Discipline</v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="etape > 2" step="2">Référence</v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="etape > 3" step="3">Épreuves</v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="etape > 4" step="4">Officiels</v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="etape > 5" step="5">En plus</v-stepper-step>
      </v-stepper-header>

      <v-progress-linear v-if="loading" indeterminate color="cyan"></v-progress-linear>

      <v-stepper-items style="height: calc(100vh - 260px); overflow-y: scroll">
        <v-stepper-content step="1">
          <Etape1
            :context="context"
            @onNextStep="etape += 1"
            @toggleLoading="loading = !loading"
            @setData="setDataEtape1"
          />
        </v-stepper-content>

        <v-stepper-content step="2">
          <Etape2
            :context="context"
            @onNextStep="etape += 1"
          />
        </v-stepper-content>

        <v-stepper-content step="3">
          <Etape3
            :context="context"
            @onNextStep="etape += 1"
          />
        </v-stepper-content>

        <v-stepper-content step="4">
          <Etape4
            :context="context"
            @onNextStep="etape += 1"
          />
        </v-stepper-content>

        <v-stepper-content step="5">
          <Etape5
            :context="context"
            @onNextStep="etape += 1"
          />
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>

  </div>
</template>

<script>
import Etape1 from "@/components/Competitions/Competitions__SaisirUneCompetitionEtape1.vue";
import Etape2 from "@/components/Competitions/Competitions__SaisirUneCompetitionEtape2.vue";
import Etape3 from "@/components/Competitions/Competitions__SaisirUneCompetitionEtape3.vue";
import Etape4 from "@/components/Competitions/Competitions__SaisirUneCompetitionEtape4.vue";
import Etape5 from "@/components/Competitions/Competitions__SaisirUneCompetitionEtape5.vue";

export default {
  components: {
    Etape1,
    Etape2,
    Etape3,
    Etape4,
    Etape5,
  },

  data: () => ({
    etape: 5,
    loading: false,
  }),

  methods: {
    setDataEtape1(payload) {
      this.etape += 1;
    },

    backToStep1() {
      this.etape = 1
    },
  },

  props: {
    context: {
      type: Object
    }
  }
};
</script>

<style scopped>
.v-stepper__label {
  text-align: center !important;
}
.v-stepper__content {
  padding: 0 !important;
}
</style>
