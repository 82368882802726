<template>
  <div class="py-10 px-6">
    <ValidateForm :loading="loading">
      <InputText
        rules="required"
        label="Président du jury"
      ></InputText>
      <InputText
        rules="required"
        label="Chef ouvreur"
      ></InputText>
      <v-btn type="submit" color="primary">Étape suivante</v-btn>
    </ValidateForm>
  </div>
</template>

<script>
import ValidateForm from "@/components/utils/form.vue";
import InputText from "@/components/utils/input.vue";

export default {
  components: {
    ValidateForm,
    InputText,
  },

  data: () => ({
    data: {
    },
    loading: false,
  }),

  methods: {
    nextStep() {
      this.$emit("onNextStep");
    },
  },

  props: {
    context: {
      type: Object,
    },
  },
};
</script>
