<template>
  <ul class="d-flex flex-column justify-space-between text-center pl-0">
    <liNav
      title="Création de compte KYC"
      module_name="gestion_lemonway_kyc_creation_compte"
      auth
    />
    <liNav
      title="Transmission des documents"
      module_name="gestion_lemonway_kyc_importation_document"
      auth
    />
    <liNav
      title="Suivi du dossier"
      module_name="gestion_lemonway_kyc_status_document"
      auth
    />
  </ul>
</template>

<script>
import liNav from "@/components/Common/Navigation__item";

export default {
  components: { liNav },

  data: () => ({}),
  created() {},

  computed: {},
};
</script>
