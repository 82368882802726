<template>
  <div class="py-10 px-6">
    <ValidateForm :loading="loading">
      <InputText
        rules="required"
        label="Nombre de place"
        type="number"
      ></InputText>
      <InputText
        label="Si cette épreuve se déroule par équipe, indiquez le nombre de personnes par équipe"
        type="number"
      ></InputText>
      <RadioGroup
        :options="[{ value:1, label:'Bloc'}, {value: 2, label: 'Difficulté' }, {value: 3, label: 'Vitesse' }]"
        label="Disciplines du combiné"
      ></RadioGroup>
      <selectInput
        rules="required"
        label="Catégorie"
      ></selectInput>
      <InputText
        rules="required"
        label="Type d'homologation"
      ></InputText>
      <selectInput
        rules="required"
        label="Licence obligatoire"
      ></selectInput>
      <Textarea
        rules="required"
        label="Primes pour l'épreuve"
      ></Textarea>
      <Textarea
        rules="required"
        label="Demande d'aménagement au réglement"
      ></Textarea>

      <v-btn type="submit" color="primary">Étape suivante</v-btn>
    </ValidateForm>
  </div>
</template>

<script>
import ValidateForm from "@/components/utils/form.vue";
import selectInput from "@/components/utils/select.vue";
import RadioGroup from "@/components/utils/radioGroup.vue";
import InputText from "@/components/utils/input.vue";
import Textarea from "@/components/utils/textarea.vue";

export default {
  components: {
    ValidateForm,
    selectInput,
    RadioGroup,
    InputText,
    Textarea,
  },

  data: () => ({
    data: {
    },
    loading: false,
  }),

  methods: {
    nextStep() {
      this.$emit("onNextStep");
    },
  },

  props: {
    context: {
      type: Object,
    },
  },
};
</script>
