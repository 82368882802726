<template>
  <div id="print-moneyInFacture" ref="print-moneyInFacture">
    <div
      class="pdf-to-print"
      style="position:absolute;left:50%;margin-left:-297px;top:0px;width:595px;height:841px;border-style:outset;overflow:hidden"
    >
      <div style="position:absolute;left:0px;top:0px">
        <img src="~@/assets/money_in/signature.jpg" width="595" height="841" />
      </div>

      <div style="position:absolute;left:64.00px;top:121.11px" class="cls_002">
        <span class="cls_002">Lemonway SAS</span>
      </div>
      <div style="position:absolute;left:64.00px;top:142.44px" class="cls_003">
        <span class="cls_003">Your payment factory</span>
      </div>
      <div style="position:absolute;left:64.00px;top:163.78px" class="cls_003">
        <span class="cls_003">8 rue du Sentier</span>
      </div>
      <div style="position:absolute;left:64.00px;top:179.88px" class="cls_003">
        <span class="cls_003">75002 Paris</span>
      </div>
      <div style="position:absolute;left:64.00px;top:195.98px" class="cls_003">
        <span class="cls_003">France</span>
      </div>
      <div style="position:absolute;left:403.96px;top:227.78px" class="cls_003">
        <span class="cls_003">Paris, {{ dataMoneyIn.date | formatDate }} </span>
      </div>
      <div style="position:absolute;left:64.00px;top:291.78px" class="cls_003">
        <span class="cls_003"
          >Objet : Justificatif de paiement - ID : {{ dataMoneyIn.id }}
        </span>
      </div>
      <div style="position:absolute;left:64.00px;top:355.78px" class="cls_003">
        <span class="cls_003">À qui de droit :</span>
      </div>
      <div style="position:absolute;left:64.00px;top:387.78px" class="cls_003">
        <span class="cls_003"
          >LEMONWAY, Établissement de Paiements régulé Banque de
          France/ACPR</span
        >
      </div>
      <div style="position:absolute;left:64.00px;top:403.88px" class="cls_003">
        <span class="cls_003"
          >(Autorité de Contrôle prudentiel et de Résolution) sous le Code
          Interbancaire</span
        >
      </div>
      <div style="position:absolute;left:64.00px;top:419.98px" class="cls_003">
        <span class="cls_003"
          >(CIB) 16568, certifie avoir reçu la somme de
          {{ dataMoneyIn.creditAmount / 100 }} €,</span
        >
      </div>
      <div style="position:absolute;left:374.70px;top:419.98px" class="cls_003">
        <span class="cls_003">en paiement par Carte,</span>
      </div>
      <div style="position:absolute;left:64.00px;top:436.08px" class="cls_003">
        <span class="cls_003"
          >en date du {{ dataMoneyIn.date | formatDateTime }} et avoir crédité
          le compte de paiement de</span
        >
      </div>
      <div style="position:absolute;left:64.00px;top:452.18px" class="cls_003">
        <span class="cls_003"
          >FFME, représenté par John SMITH, pour le même montant en date
          du</span
        >
      </div>
      <div style="position:absolute;left:64.00px;top:468.28px" class="cls_003">
        <span class="cls_003">{{ dataMoneyIn.date | formatDateTime }}.</span>
      </div>
      <div style="position:absolute;left:64.00px;top:515.78px" class="cls_003">
        <span class="cls_003">Pour faire valoir ce que de droit.</span>
      </div>
      <div style="position:absolute;left:405.94px;top:547.78px" class="cls_003">
        <span class="cls_003">Chief executive officer</span>
      </div>
      <div style="position:absolute;left:445.36px;top:569.11px" class="cls_003">
        <span class="cls_003">Antoine Orsini</span>
      </div>
      <div style="position:absolute;left:64.00px;top:770.00px" class="cls_004">
        <span class="cls_004"
          >Lemonway - Société par actions simplifiée - RCS Paris 500 486
          915</span
        >
      </div>
      <div style="position:absolute;left:64.00px;top:778.05px" class="cls_004">
        <span class="cls_004"
          >Siège social : 8, rue du Sentier - 75002 Paris - France</span
        >
      </div>
      <div style="position:absolute;left:64.00px;top:786.10px" class="cls_004">
        <span class="cls_004"
          >Etablissement de paiement agréé par l’ACPR sous le numéro 16568</span
        >
      </div>
      <div style="position:absolute;left:64.00px;top:795.33px" class="cls_005">
        <span class="cls_005"> </span
        ><A HREF="https://www.lemonway.com">https://www.lemonway.com</A>
        <span class="cls_004"> - Téléphone : +33 1 76 44 04 60</span>
      </div>
    </div>
  </div>
</template>

<script>
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { mapActions, mapGetters } from 'vuex';

export default {
  data: () => ({
    dataMoneyIn: null,
  }),

  methods: {
    async printMoneyInFacture(context) {
      this.dataMoneyIn = context?.transactionIn;
      console.log('this.dataMoneyIn printtt', this.dataMoneyIn);

      this.$refs['print-moneyInFacture'].style.display = 'block';

      let pdf = new jsPDF({
        orientation: 'portrait',
      });
      let sources = document.getElementsByClassName('pdf-to-print');
      let promisePdf = [];
      sources.forEach((source) => {
        return promisePdf.push(
          html2canvas(source, {
            letterRendering: 1,
            logging: true,
            scale: 2,
            useCORS: true,
          })
        );
      });
      await Promise.all(promisePdf).then((canvases) => {
        canvases.forEach((canvas, index) => {
          const imgData = canvas.toDataURL('image/jpeg');
          const imgProps = pdf.getImageProperties(imgData);
          const pdfWidth = pdf.internal.pageSize.getWidth();
          const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
          if (index !== 0) pdf.addPage();
          pdf.addImage(imgData, 'JPEG', 0, 0, pdfWidth, pdfHeight);
        });
      });
      pdf.save('Facture Money-In');
      this.$refs['print-moneyInFacture'].style.display = 'none';
    },
  },

  filters: {
    formatDate(value) {
      if (!value) return '';
      const milliseconds = value * 1000;
      const dateObject = new Date(milliseconds);
      const frDateFormat = dateObject.toLocaleDateString('fr-FR');
      return frDateFormat;
    },
    formatDateTime(value) {
      if (!value) return '';
      const milliseconds = value * 1000;
      const dateObject = new Date(milliseconds);
      const frDateFormat = dateObject.toLocaleString('fr-FR');
      return frDateFormat;
    },
  },
};
</script>
<style lang="scss">
#print-moneyInFacture {
  width: 100%;
  position: absolute;
  z-index: -3;
  position: absolute;
  display: none;

  span.cls_002 {
    font-family: Arial, serif;
    font-size: 14.1px;
    color: rgb(86, 236, 124);
    font-weight: normal;
    font-style: normal;
    text-decoration: none;
  }
  div.cls_002 {
    font-family: Arial, serif;
    font-size: 14.1px;
    color: rgb(86, 236, 124);
    font-weight: normal;
    font-style: normal;
    text-decoration: none;
  }
  span.cls_003 {
    font-family: Arial, serif;
    font-size: 13.1px;
    color: rgb(86, 91, 119);
    font-weight: normal;
    font-style: normal;
    text-decoration: none;
  }
  div.cls_003 {
    font-family: Arial, serif;
    font-size: 14.1px;
    color: rgb(86, 91, 119);
    font-weight: normal;
    font-style: normal;
    text-decoration: none;
  }
  span.cls_004 {
    font-family: Arial, serif;
    font-size: 7px;
    color: rgb(86, 91, 119);
    font-weight: normal;
    font-style: normal;
    text-decoration: none;
  }
  div.cls_004 {
    font-family: Arial, serif;
    font-size: 7px;
    color: rgb(86, 91, 119);
    font-weight: normal;
    font-style: normal;
    text-decoration: none;
  }
  span.cls_005 {
    font-family: Arial, serif;
    font-size: 7px;
    color: rgb(86, 236, 124);
    font-weight: normal;
    font-style: normal;
    text-decoration: none;
  }
  div.cls_005 {
    font-family: Arial, serif;
    font-size: 7px;
    color: rgb(86, 236, 124);
    font-weight: normal;
    font-style: normal;
    text-decoration: none;
  }
}
</style>
