<template>
  <v-dialog
    v-if="openModal"
    v-model="openModal"
    @click:outside="close()"
    max-width="600px"
    content-class="align-items-center"
  >
    <div class="p-0 m-0 bg-white">
      <!-- <v-row no-gutters>
        <SubBar :title="subBarTitle2"  />
      </v-row> -->
      <v-row class="m-0">
        <v-col cols="12">
          <v-row>
            <v-col class="d-flex" cols="12" style="justify-content: center;">
              <span class="mr-5 title-place-marche"
                >Description de la transaction</span
              >
              <!-- <v-divider class="ml-5"></v-divider> -->
            </v-col>
            <v-col cols="12" class="d-block" v-if="data">
              <v-row class="m-0 py-1">
                <v-col cols="3" class="p-0">
                  <span class="coordonne">Motif du transfert *</span>
                </v-col>
                <v-col cols="9" class="py-0">
                  <p class="phrase d-flex mb-0">
                    {{ data.transfertComment }}
                  </p>
                </v-col>
              </v-row>
              <v-row class="m-0 py-1">
                <v-col cols="3" class="p-0">
                  <span class="mr-5 coordonne">Montant du transfert *</span>
                </v-col>
                <v-col cols="9" class="py-0">
                  <p class="phrase d-flex mb-0">{{ data.transfertAmount }} €</p>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="m-0 p-1 backgrounded">
        <p class="mb-0 section-place-marche-prix">
          Information : La commission de la transaction s’élève à 0.10 € par
          transfert. Ces fonds seront crédités par la FFME via votre compte
          LemonWay. Le transfert sera effectif sur votre compte entre 1 & 3
          jours.
        </p>
      </v-row>
      <v-row class="m-0">
        <v-col cols="12" class="d-flex mt-5 mb-5">
          <v-btn
            @click="sendTransfert"
            class="btn btn-primary"
            :loading="loading"
            >Procéder au transfert</v-btn
          >
          <span class="clear-filter p-1 px-3 ml-5" @click="close"
            >Annuler l'action</span
          >
        </v-col>
      </v-row>
    </div>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {
  error_notification,
  success_notification,
} from '@/plugins/notifications';

export default {
  components: {
    // SubBar,
  },

  data: () => ({
    loading: false,
    mounted: false,
    subBarTitle2: 'RECAPITULATIF DE LA TRANSACTION',
  }),

  async created() {
    console.log('dataaaaa', this.data);
  },

  computed: {},

  methods: {
    close() {
      this.openModal = false;
    },
    sendTransfert() {
      this.$emit('onSend');
      this.loading = false;
      this.openModal = false;
    },
  },

  props: {
    openModal: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang="scss">
.clear-filter {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 12px;
  /* identical to box height */
  border: 1px solid rgba(8, 43, 110, 0.25);
  border-radius: 30px;
  display: flex;
  align-items: center;
  cursor: pointer;

  /* Primary/Blue */

  color: #082b6e;
}
.backgrounded {
  background: rgba(8, 43, 110, 0.1);
  border-left: 2px solid #082b6e;
}
.main-place-marche-tab {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */

  display: flex;
  align-items: center;

  /* Primary/Royal */
  text-transform: none !important;
  color: #082b6e;
}
.left-bar {
  background: #e7effd;
}
.icon-color {
  &.first-blue {
    color: #1c3d7a !important;
  }
}
.title-place-marche {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  /* or 143% */

  display: flex;
  align-items: center;

  /* Grey/100 */

  color: #1c3d7a;
}
.avatar-bank {
  width: 40px;
  height: 40px;
  background: #ffffff;
  box-shadow: 2px 4px 8px -2px rgba(8, 43, 110, 0.25);
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.coordonne {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 10px !important;
  line-height: 16px;
  /* identical to box height, or 160% */

  display: flex;
  align-items: center;
  letter-spacing: 1px;
  text-transform: uppercase;

  /* Primary/Dark */

  color: #000000 !important;
}
.phrase {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  align-items: center;

  /* Grey/70 */

  color: #000000;
}
.rib-bank {
  p {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */

    display: flex;
    align-items: center;

    /* Grey/70 */

    color: #90a1be;
  }

  span {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    /* identical to box height, or 133% */

    display: flex;
    align-items: center;

    /* Grey/50 */

    color: #bac4d7;
    &.coordonne {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 10px !important;
      line-height: 16px;
      /* identical to box height, or 160% */

      display: flex;
      align-items: center;
      letter-spacing: 1px;
      text-transform: uppercase;

      /* Primary/Dark */

      color: #000000 !important;
    }
  }
}
.section-place-marche {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}
.section-place-marche-prix {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 32px;
  /* identical to box height, or 133% */

  display: flex;
  align-items: center;
  letter-spacing: -0.5px;

  /* Primary/Royal */

  color: #082b6e;
}
.rib {
  /* Primary/White */
  background: #ffffff;
  /* Shadow/Royal */
  box-shadow: 2px 4px 8px -2px rgba(8, 43, 110, 0.25);
  border-radius: 4px;
}
</style>
