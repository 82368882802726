<template>
  <div class>
    <v-row no-gutters>
      <SubBar
        :title="subBarTitle"
        route_name="gestion_comptabilite__gestion_des_factures"
      />
    </v-row>
    <v-row>
        <v-col v-if="!mounted">
            Chargement...
        </v-col>
        <v-col v-else>

            <v-row class="px-4">
                <v-col cols="12">
                    <v-row>
                        <v-col cols="3">
                            Date: {{$moment(flux.Z_DateCreation).format("DD/MM/YYYY")}}
                        </v-col>
                        <v-col cols="3">
                            Reglement: {{ flux.EST_Reglement }}
                        </v-col>
                        <v-col cols="3">
                            ID_Stripe: <span class="font-weight-bold">{{ flux.ID_PI_Stripe }}</span>
                        </v-col>
                        <v-col cols="3">
                            Total: <span class="font-weight-bold">{{ flux.MontantHT  }}€</span>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col class="12" lg="6">
                    <h5>Utilisateur</h5>
                    <div><span class="font-weight-bold">Nom</span>: {{flux.UTI_Utilisateur.CT_Nom}}</div>
                    <div><span class="font-weight-bold">Prénom</span>: {{flux.UTI_Utilisateur.CT_Prenom}}</div>
                    <div><span class="font-weight-bold">Email</span>: {{flux.UTI_Utilisateur.CT_Email}}</div>
                    <div><span class="font-weight-bold">Téléphone</span>: {{flux.UTI_Utilisateur.CT_TelephoneFixe }}</div>
                </v-col>

                <v-col class="12" lg="6">
                    <h5>Structure</h5>
                    <div><span class="font-weight-bold">Nom</span>: {{flux.STR_Structure.NomStructure}}</div>
                </v-col>

            </v-row>
              <v-simple-table fixed-header height="300px">
                <template v-slot:default>
                    <thead>
                        <tr>
                        <th class="text-left">Nom</th>
                        <th class="text-left">Montant</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(flux, index) in flux.MKP_FluxFinancierDetail" :key="index">
                        <td>{{ flux.NomFluxFinancierDetail }}</td>
                        <td>{{ flux.MontantTTC }}€</td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
            <div class="text-right px-10">
              Total: <span class="font-weight-bold">{{ flux.MontantHT  }}€</span>
            </div>
        </v-col>
    </v-row> 
 
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import SubBar from "@/components/Common/Common__SubBar.vue";
import {error_notification} from "@/plugins/notifications"
export default {
    components: { SubBar },

    data: () => ({
        mounted: false
    }),

    async created() {

        let regex = new RegExp("^[0-9]*$");

        if (!regex.test(this.$route.params.id) || ! this.hasAuthization )  {
            return this.redirect('Page introuvable')
        }

        let flux = await this.getFluxDetail({ID_Flux: this.$route.params.id})

        if(flux[0]) {
            this.flux = flux[0]
        }else {
            return this.redirect('Aucuns flux trouvé avec l\'id '+ this.$route.params.id)
        }

        this.mounted = true
        console.log("FLUX", this.flux)
        return
    },

    computed: {
        ...mapGetters('user', ['can']),
        ...mapGetters('structure', ['currentStructureId']),

        subBarTitle() {
            let title = "Detail facture"
            if(this.mounted) title += " - " + this.flux.NomFluxFinancier
            return title;
        },

        hasAuthization() {
            return this.can('gestion_comptabilite__detail_facture')
        },
    },

    methods: {
        ...mapActions('structure', ['getFluxDetail']),

        redirect($message) {
            error_notification($message)
            return this.$router.push({ name: "gestion_comptabilite__gestion_des_factures" });
        },
   
    },
};
</script>

<style lang="scss" scoped>
.loading {
  background: red;
}
</style>
