<template>
  <div class="h-100 position-relative">
    <v-row>
      <v-col cols="12">
        <h1 class="stepper-title py-5 px-5">Etape 2 - Documents à joindre</h1>
      </v-col>
    </v-row>
    <v-row class="ml-1" v-if="!isEA">
      <v-col class="stepper-info p-0" cols="12">
        <span class="stepper-info-text d-block p-3">
          <strong>Informations</strong> : Dans le cadre de la procédure KYC,
          chaque nouvel établissement affilié doit fournir les pièces
          justificatives suivantes, <br /><br /><strong
            >Une pièce d'identité parmi les 4 suivantes :</strong
          ><br />
          - Passeport <br />
          - Carte d’identité <br />
          - Titre de séjour <br />
          - Permis de conduire <br />
        </span>
      </v-col>
      <div>
        <v-alert
          type="error"
          dense
          border="left"
          colored-border
          color="red lighten-2"
          class="attention"
        >
          Fichiers autorisés : PDF, JPG, JPEG et PNG
        </v-alert>
      </div>
    </v-row>
    <v-row class="ml-1" v-if="isEA">
      <v-col class="stepper-info p-0" cols="12">
        <span class="stepper-info-text d-block p-3">
          <strong>Informations</strong> : Dans le cadre de la procédure KYC,
          chaque nouvel établissement affilié doit fournir les pièces
          justificatives suivantes, <br /><br /><strong
            >Un document officiel présentant l’actionnariat de la société parmi
            les 2 suivants :</strong
          ><br />
          - Table de capitalisation avec % de détention <br />
          - Formulaire bénéficiaire effectif type DBE-S1 <br /><br />
          <strong
            >Un KBIS datant de moins de trois mois de la société titulaire du
            compte de paiement, attestant de la création de
            l’entreprise.</strong
          >
        </span>
      </v-col>
    </v-row>

    <ValidateForm @validated="submit" :showFieldObl="false">
      <v-row class="mt-5 ml-3 mb-5 pr-3" v-if="!isEA">
        <v-col class="d-flex" cols="12">
          <span class="stepper-menu-subtitle">
            Documents à téléverser
          </span>
          <v-divider class="ml-5"></v-divider>
        </v-col>

        <v-col
          sm="12"
          lg="6"
          class="py-0 d-flex jsutify-content-center position-relative align-items-baseline"
          cols="6"
        >
          <div class="w-25">
            <span class="custom-label">Pièce d’identité *</span>
          </div>
          <div class="w-75 d-flex position-relative">
            <v-list class="w-100">
              <v-list-group prepend-icon="mdi-paperclip" no-action>
                <template v-slot:activator>
                  <v-list-item-content>
                    <v-list-item-title class="custom-label"
                      >Téléverser une pièce d’identité</v-list-item-title
                    >
                  </v-list-item-content>
                </template>

                <v-list-item
                  v-for="(doc, i) in allDocumentType"
                  :key="doc.value"
                  class="p-1 h-0"
                >
                  <v-list-item-icon class="m-0 d-contents">
                    <v-icon>mdi-paperclip</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title
                      class="select-attachement-text "
                      v-text="doc.name"
                    ></v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <div>
                      <input
                        style="display: none"
                        accept=".jpg,.jpeg,.png,.pdf"
                        @change="processFilePieceIdentity"
                        type="file"
                        ref="fileInput"
                      />
                      <v-btn
                        icon
                        @click="triggerWindowUpload(i)"
                        :disabled="doc.disabled"
                      >
                        <v-icon color="#082B6E">mdi-open-in-new</v-icon>
                      </v-btn>
                      <v-btn
                        icon
                        :disabled="doc.disabled"
                        @click="triggerRemoveFile(i)"
                      >
                        <v-icon color="#082B6E">mdi-close</v-icon>
                      </v-btn>
                    </div>
                  </v-list-item-action>
                </v-list-item>
              </v-list-group>
            </v-list>
          </div>
        </v-col>

        <v-col sm="12" lg="6" class="py-0" cols="6"></v-col>

        <v-col
          sm="12"
          lg="6"
          class="py-0 d-flex jsutify-content-center position-relative align-items-baseline"
          cols="6"
        >
          <div class="w-25">
            <span class="custom-label">Procès verbal *</span>
          </div>
          <div class="w-75 d-flex position-relative">
            <v-file-input
              required
              :rules="[(v) => !!v || 'Veuillez sélectionner un fichier']"
              dense
              outlined
              show-size
              prepend-icon=""
              prepend-inner-icon="mdi-paperclip"
              @change="processFileProcesVerbal"
              accept="image/jpeg,image/png,image/jpg, application/pdf"
            ></v-file-input>
            <svg
              v-if="!isFileProcesVerbal"
              class="icon-style mt-3 mr-3"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path
                fill="#082B6E"
                d="M432,320H400a16,16,0,0,0-16,16V448H64V128H208a16,16,0,0,0,16-16V80a16,16,0,0,0-16-16H48A48,48,0,0,0,0,112V464a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V336A16,16,0,0,0,432,320ZM488,0h-128c-21.37,0-32.05,25.91-17,41l35.73,35.73L135,320.37a24,24,0,0,0,0,34L157.67,377a24,24,0,0,0,34,0L435.28,133.32,471,169c15,15,41,4.5,41-17V24A24,24,0,0,0,488,0Z"
              />
            </svg>
          </div>
        </v-col>

        <v-col sm="12" lg="6" class="py-0" cols="6"></v-col>
        <v-col
          sm="12"
          lg="6"
          class="py-0 d-flex jsutify-content-center position-relative align-items-baseline"
          cols="6"
        >
          <div class="w-25">
            <span class="custom-label">Statuts datés et signés *</span>
          </div>
          <div class="w-75 d-flex position-relative">
            <v-file-input
              required
              :rules="[(v) => !!v || 'Veuillez sélectionner un fichier']"
              dense
              outlined
              prepend-icon=""
              prepend-inner-icon="mdi-paperclip"
              show-size
              @change="processFileDateSigne"
              accept="image/jpeg,image/png,image/jpg, application/pdf"
            ></v-file-input>
            <svg
              v-if="!isFileDateSigne"
              class="icon-style mt-3 mr-3"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path
                fill="#082B6E"
                d="M432,320H400a16,16,0,0,0-16,16V448H64V128H208a16,16,0,0,0,16-16V80a16,16,0,0,0-16-16H48A48,48,0,0,0,0,112V464a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V336A16,16,0,0,0,432,320ZM488,0h-128c-21.37,0-32.05,25.91-17,41l35.73,35.73L135,320.37a24,24,0,0,0,0,34L157.67,377a24,24,0,0,0,34,0L435.28,133.32,471,169c15,15,41,4.5,41-17V24A24,24,0,0,0,488,0Z"
              />
            </svg>
          </div>
        </v-col>

        <v-col sm="12" lg="6" class="py-0" cols="6"></v-col>

        <v-col
          sm="12"
          lg="6"
          class="py-0 d-flex jsutify-content-center position-relative align-items-baseline"
          cols="6"
        >
          <div class="w-25">
            <span class="custom-label">Parution JOAFE *</span>
          </div>
          <div class="w-75 d-flex position-relative">
            <v-file-input
              required
              :rules="[(v) => !!v || 'Veuillez sélectionner un fichier']"
              dense
              outlined
              show-size
              prepend-icon=""
              prepend-inner-icon="mdi-paperclip"
              @change="processFileJaofe"
              accept="image/jpeg,image/png,image/jpg, application/pdf"
            ></v-file-input>
            <svg
              v-if="!isFileJaofe"
              class="icon-style mt-3 mr-3"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path
                fill="#082B6E"
                d="M432,320H400a16,16,0,0,0-16,16V448H64V128H208a16,16,0,0,0,16-16V80a16,16,0,0,0-16-16H48A48,48,0,0,0,0,112V464a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V336A16,16,0,0,0,432,320ZM488,0h-128c-21.37,0-32.05,25.91-17,41l35.73,35.73L135,320.37a24,24,0,0,0,0,34L157.67,377a24,24,0,0,0,34,0L435.28,133.32,471,169c15,15,41,4.5,41-17V24A24,24,0,0,0,488,0Z"
              />
            </svg>
          </div>
        </v-col>

        <v-col sm="12" lg="6" class="py-0" cols="6"></v-col>

        <v-col cols="12" class="py-0 d-flex justify-content-end">
          <v-btn
            type="submit"
            class="btn btn-primary stepper-action m-3"
            :loading="loading"
            :disabled="isDisabled"
            >Valider les différents documents</v-btn
          >
        </v-col>
      </v-row>

      <v-row class="mt-5 ml-3 mb-5 pr-3" v-if="isEA">
        <v-col class="d-flex" cols="12">
          <span class="stepper-menu-subtitle">
            Documents à téléverser
          </span>
          <v-divider class="ml-5"></v-divider>
        </v-col>

        <v-col
          sm="12"
          lg="6"
          class="py-0 d-flex jsutify-content-center position-relative align-items-baseline"
          cols="6"
        >
          <div class="w-25">
            <span class="custom-label">Actionnariat de la société *</span>
          </div>
          <div class="w-75 d-flex position-relative">
            <v-list class="w-100">
              <v-list-group prepend-icon="mdi-paperclip" no-action>
                <template v-slot:activator>
                  <v-list-item-content>
                    <v-list-item-title class="custom-label"
                      >Téléverser le document</v-list-item-title
                    >
                  </v-list-item-content>
                </template>

                <v-list-item
                  v-for="(doc, i) in allDocumentTypeEA"
                  :key="doc.value"
                  class="p-1 h-0"
                >
                  <v-list-item-icon class="m-0 d-contents">
                    <v-icon>mdi-paperclip</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title
                      class="select-attachement-text "
                      v-text="doc.name"
                    ></v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <div>
                      <input
                        style="display: none"
                        accept=".jpg,.jpeg,.png,.pdf"
                        @change="processFilePieceIdentityEA"
                        type="file"
                        ref="fileInput"
                      />
                      <v-btn
                        icon
                        @click="triggerWindowUpload(i)"
                        :disabled="doc.disabled"
                      >
                        <v-icon color="#082B6E">mdi-open-in-new</v-icon>
                      </v-btn>
                      <v-btn
                        icon
                        :disabled="doc.disabled"
                        @click="triggerRemoveFile(i)"
                      >
                        <v-icon color="#082B6E">mdi-close</v-icon>
                      </v-btn>
                    </div>
                  </v-list-item-action>
                </v-list-item>
              </v-list-group>
            </v-list>
          </div>
        </v-col>

        <v-col sm="12" lg="6" class="py-0" cols="6"></v-col>
        <v-col
          sm="12"
          lg="6"
          class="py-0 d-flex jsutify-content-center position-relative align-items-baseline"
          cols="6"
        >
          <div class="w-25">
            <span class="custom-label">Status datés et signés *</span>
          </div>
          <div class="w-75 d-flex position-relative">
            <v-file-input
              required
              :rules="[(v) => !!v || 'Veuillez sélectionner un fichier']"
              dense
              outlined
              prepend-icon=""
              prepend-inner-icon="mdi-paperclip"
              show-size
              @change="processFileDateSigne"
              accept="image/jpeg,image/png,image/jpg, application/pdf"
            ></v-file-input>
            <svg
              v-if="!isFileDateSigne"
              class="icon-style mt-3 mr-3"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path
                fill="#082B6E"
                d="M432,320H400a16,16,0,0,0-16,16V448H64V128H208a16,16,0,0,0,16-16V80a16,16,0,0,0-16-16H48A48,48,0,0,0,0,112V464a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V336A16,16,0,0,0,432,320ZM488,0h-128c-21.37,0-32.05,25.91-17,41l35.73,35.73L135,320.37a24,24,0,0,0,0,34L157.67,377a24,24,0,0,0,34,0L435.28,133.32,471,169c15,15,41,4.5,41-17V24A24,24,0,0,0,488,0Z"
              />
            </svg>
          </div>
        </v-col>

        <v-col sm="12" lg="6" class="py-0" cols="6"></v-col>

        <v-col
          sm="12"
          lg="6"
          class="py-0 d-flex jsutify-content-center position-relative align-items-baseline"
          cols="6"
        >
          <div class="w-25">
            <span class="custom-label">KBIS *</span>
          </div>
          <div class="w-75 d-flex position-relative">
            <v-file-input
              required
              :rules="[(v) => !!v || 'Veuillez sélectionner un fichier']"
              dense
              outlined
              show-size
              prepend-icon=""
              prepend-inner-icon="mdi-paperclip"
              @change="processFileKbis"
              accept="image/jpeg,image/png,image/jpg, application/pdf"
            ></v-file-input>
            <svg
              v-if="!isFileKbis"
              class="icon-style mt-3 mr-3"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path
                fill="#082B6E"
                d="M432,320H400a16,16,0,0,0-16,16V448H64V128H208a16,16,0,0,0,16-16V80a16,16,0,0,0-16-16H48A48,48,0,0,0,0,112V464a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V336A16,16,0,0,0,432,320ZM488,0h-128c-21.37,0-32.05,25.91-17,41l35.73,35.73L135,320.37a24,24,0,0,0,0,34L157.67,377a24,24,0,0,0,34,0L435.28,133.32,471,169c15,15,41,4.5,41-17V24A24,24,0,0,0,488,0Z"
              />
            </svg>
          </div>
        </v-col>

        <v-col sm="12" lg="6" class="py-0" cols="6"></v-col>

        <v-col cols="12" class="py-0 d-flex justify-content-end">
          <v-btn
            type="submit"
            class="btn btn-primary stepper-action m-3"
            :loading="loading"
            :disabled="isDisabled"
            >Valider les différentes documents</v-btn
          >
        </v-col>
      </v-row>
    </ValidateForm>
  </div>
</template>

<script>
import ValidateForm from '@/components/utils/form.vue';
import { mapGetters, mapActions } from 'vuex';
import {
  error_notification,
  success_notification,
} from '@/plugins/notifications';
import fetch from "node-fetch";

export default {
  components: {
    ValidateForm,
  },

  data: () => ({
    loading: false,
    // isEa: true,
    getAllDocumentTypeDB: [],
    allDocumentType: [
      { id: 0, name: 'Passeport (UE)', value: 3, disabled: false },
      { id: 1, name: 'Passeport (hors UE)', value: 4, disabled: false },
      { id: 2, name: "Carte d'identité", value: 0, disabled: false },
      { id: 3, name: 'Titre de séjour', value: 5, disabled: false },
      { id: 4, name: 'Permis de conduire', value: 11, disabled: false },
      // { name: 'Procès-verbal association (moins d’un an)', value: 17 },
      // { name: 'Statuts datés et signés', value: 12 },
      // { name: 'Parution au JOAFE', value: 18 },
    ],
    allDocumentTypeEA: [
      { id: 0, name: 'Table de capitalisation', value: 19, disabled: false },
      { id: 1, name: 'Formulaire type DBE-S1', value: 20, disabled: false },
    ],
    getAllPaymentAccount: [],
    file: '',
    isFileProcesVerbal: '',
    isFileDateSigne: '',
    isFileJaofe: '',
    isFileKbis: '',
    structure: {},
    currentFileInput: null,
    pieceIdentitySelected: null,
    payloadPieceIdentity: {},
    payloadPieceIdentityEA: {},
    payloadProcesVerbal: {},
    payloadDateSigne: {},
    payloadJaofe: {},
    payloadKbis: {},
    payloadS3PieceIdentity: {},
    payloadS3PieceIdentityEA: {},
    payloadS3ProcesVerbal: {},
    payloadS3DateSigne: {},
    payloadS3Jaofe: {},
    payloadS3Kbis: {},
    isCorrectFileSize: false,
  }),

  async created() {
    await this.setData();
  },

  computed: {
    ...mapGetters('user', ['currentUser']),
    ...mapGetters('structure', [
      'currentStructureId',
      'currentStructure',
      'currentStructureType',
    ]),

    isEA() {
      return (
        this.currentStructureType.SlugTypeStructure ==
        'club_etablissement_affilie'
      );
    },

    alreadyRegister() {
      if (this.accountKyc === undefined) return false;
      return true;
    },
    account() {
      return this.accountKyc;
    },
    documentUpload() {
      let documents = this.allDocumentType;
      console.log('atoooooooo', this.structure);
      const slug = this.structure?.STR_Structure?.LIST_StructureType
        .SlugTypeStructure;
      if (slug === 'club_etablissement_affilie') {
        documents = [
          {
            name: "Carte d'identité (Obligatoire)",
            value: 0,
          },
          {
            name: 'Bénéficiaire effectif (Facultatif)',
            value: 19,
          },
          { name: 'KBIS - attestant création (moins de trois mois)', value: 7 },
          { name: 'Statuts datés et signés', value: 12 },
        ];
      }
      return documents;
    },

    documentType() {
      let cat = this.getAllDocumentTypeDB.find((doc) => doc.id === 61);
      if (cat) return cat;
      return null;
    },

    isDisabled() {
      const maxFileSize = 5_000_000;
      if (!this.isEA) {
        if (
          this.file &&
          this.file.size < maxFileSize &&
          this.isFileProcesVerbal &&
          this.isFileProcesVerbal.size < maxFileSize &&
          this.isFileDateSigne &&
          this.isFileDateSigne.size < maxFileSize &&
          this.isFileJaofe &&
          this.isFileJaofe.size < maxFileSize
        )
          return false;
        return true;
      } else {
        if (
          this.file &&
          this.file.size < maxFileSize &&
          this.isFileDateSigne &&
          this.isFileDateSigne.size < maxFileSize &&
          this.isFileKbis &&
          this.isFileKbis.size < maxFileSize
        )
          return false;
        return true;
      }
    },
  },

  // watch: {
  //   procesVerbal(file) {
  //     console.log('procesVerbal', file);
  //   },
  // },

  methods: {
    ...mapActions('utilisateurs', [
      'getUserPaymentAccount',
      'getUserPaymentAccountStructure',
    ]),
    ...mapActions('basics', ['getListTypesDocument', 'insertDocument']),

    async setData() {
      this.getAllDocumentTypeDB = await this.getListTypesDocument();

      this.getAllPaymentAccount = await this.getUserPaymentAccountStructure(
        this.currentStructureId
      );
      // this.haveAccount = this.getAllPaymentAccount.find(
      //   (account) => account.ID_Structure == this.currentStructureId
      // );
      // console.log("this.haveAccount !!!!", this.haveAccount);

      this.accountKyc = this.getAllPaymentAccount[0]?.NomCompte;
      console.log('this.accountKyc  !!!!', this.accountKyc);

      this.structure = this.currentStructure;
      console.log('this.structure  !!!!', this.structure);
    },

    // Simulate the click on the hidenn input file
    triggerWindowUpload(i) {
      console.log('this.$refs', i);
      this.$refs.fileInput[i].click();
      this.currentFileInput = i;
    },

    triggerRemoveFile(i) {
      console.log('triggerRemoveFile', this.$refs.fileInput[i]);
      this.file = null;
      // this.isFileProcesVerbal = null;
      // this.isFileDateSigne = null;
      // this.isFileJaofe = null;
      // this.isFileKbis = null;

      this.allDocumentType = [
        { id: 0, name: 'Passeport (UE)', value: 3, disabled: false },
        { id: 1, name: 'Passeport (hors UE)', value: 4, disabled: false },
        { id: 2, name: "Carte d'identité", value: 0, disabled: false },
        { id: 3, name: 'Titre de séjour', value: 5, disabled: false },
        { id: 4, name: 'Permis de conduire', value: 11, disabled: false },
      ];

      this.allDocumentTypeEA = [
        { id: 0, name: 'Table de capitalisation', value: 19, disabled: false },
        { id: 1, name: 'Formulaire type DBE-S1', value: 20, disabled: false },
      ];
    },

    // convert the input value into file object and check his validity
    async processFilePieceIdentity(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;

      this.file = files[0];
      console.log('this filessssss', this.file);
      let fileExt = this.file.type.split('/');
      console.log('fileExt fileExt', fileExt);

      if (this.file) {
        if (this.currentFileInput !== null) {
          let docSelected = this.allDocumentType?.find(
            (selected) => selected.id === this.currentFileInput
          );
          this.pieceIdentitySelected = docSelected?.value;
          if (docSelected) {
            this.allDocumentType?.map((doc) => {
              if (doc.id !== docSelected.id) {
                doc.disabled = true;
              }
            });
          }
        }
      }

      var reader = new FileReader();
      let fileS3PieceIdentity = '';
      reader.readAsDataURL(this.file);
      reader.onload = async (event) => {
        try {
          fileS3PieceIdentity = event.target.result;

          let splitBase64 = reader.result.split(',');
          this.iconBase64 = await splitBase64[1];
        } catch (error) {
          console.log('error catch', error);
        }
      };

      reader.onerror = function(error) {
        console.log('Error: ', error);
      };
      setTimeout(() => {
        this.payloadPieceIdentity = {
          name: this.file?.name,
          type: this.pieceIdentitySelected,
          buffer: this.iconBase64,
        };
        // console.log('this.payloadPieceIdentity : ', this.payloadPieceIdentity);
        const hash =
          Math.random()
            .toString(36)
            .substring(2, 15) +
          Math.random()
            .toString(36)
            .substring(2, 15);

        this.payloadS3PieceIdentity = {
          image: fileS3PieceIdentity,
          key: `"piece_identite"_${this.currentStructureId}_${hash}${+'.' +
            fileExt[1]}`,
          ID_Structure: this.currentStructureId,
        };
        console.log(
          'this.payloadS3PieceIdentity : ',
          this.payloadS3PieceIdentity
        );
      }, 100);

      // Check size and file type
      if (!(await this.checkFile(this.file))) return false;

      await this.readFile(this.file);

      return true;
    },

    async processFilePieceIdentityEA(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;

      this.file = files[0];
      console.log('this filessssss', this.file);
      let fileExt = this.file.type.split('/');
      console.log('fileExt fileExt', fileExt);

      if (this.file) {
        if (this.currentFileInput !== null) {
          let docSelected = this.allDocumentTypeEA?.find(
            (selected) => selected.id === this.currentFileInput
          );
          this.pieceIdentitySelected = docSelected?.value;
          if (docSelected) {
            this.allDocumentTypeEA?.map((doc) => {
              if (doc.id !== docSelected.id) {
                doc.disabled = true;
              }
            });
          }
        }
      }

      var reader = new FileReader();
      let fileS3PieceIdentity = '';
      reader.readAsDataURL(this.file);
      reader.onload = async (event) => {
        try {
          fileS3PieceIdentity = event.target.result;

          let splitBase64 = reader.result.split(',');
          this.iconBase64 = await splitBase64[1];
        } catch (error) {
          console.log('error catch', error);
        }
      };

      reader.onerror = function(error) {
        console.log('Error: ', error);
      };
      setTimeout(() => {
        const hash =
          Math.random()
            .toString(36)
            .substring(2, 15) +
          Math.random()
            .toString(36)
            .substring(2, 15);

        this.payloadPieceIdentityEA = {
          name: this.file?.name,
          type: this.pieceIdentitySelected,
          buffer: this.iconBase64,
        };
        // console.log('this.payloadPieceIdentityEA : ', this.payloadPieceIdentityEA);

        this.payloadS3PieceIdentityEA = {
          image: fileS3PieceIdentity,
          key: `"actionnariat_societe"_${
            this.currentStructureId
          }_${hash}${+'.' + fileExt[1]}`,
          ID_Structure: this.currentStructureId,
        };
        console.log(
          'this.payloadS3PieceIdentityEA : ',
          this.payloadS3PieceIdentityEA
        );
      }, 100);

      // Check size and file type
      if (!(await this.checkFile(this.file))) return false;

      await this.readFile(this.file);

      return true;
    },

    async processFileProcesVerbal(e) {
      console.log('filesss verbal', e);
      this.isFileProcesVerbal = e;
      let files = e;
      let fileExt = files.type?.split('/');
      console.log('fileExt fileExt', fileExt);

      var reader = new FileReader();
      let fileS3ProcesVerbal = '';
      reader.readAsDataURL(files);
      reader.onload = (event) => {
        fileS3ProcesVerbal = event.target.result;
        this.payloadS3ProcesVerbal = event.target.result;

        const splitBase64 = reader?.result.split(',');
        this.iconBase64 = splitBase64[1];
      };
      setTimeout(() => {
        this.payloadProcesVerbal = {
          name: this.file?.name,
          type: 17,
          buffer: this.iconBase64,
        };

        console.log('this.payloadProcesVerbal : ', this.payloadProcesVerbal);
        const hash =
          Math.random()
            .toString(36)
            .substring(2, 15) +
          Math.random()
            .toString(36)
            .substring(2, 15);

        this.payloadS3ProcesVerbal = {
          image: fileS3ProcesVerbal,
          key: `"piece_proces_verbal"_${this.currentStructureId}_${hash}${+'.' +
            fileExt[1]}`,
          ID_Structure: this.currentStructureId,
        };
      }, 100);

      reader.onerror = function(error) {
        console.log('Error: ', error);
      };

      // Check size and file type
      if (!(await this.checkFile(files))) return false;

      await this.readFile(files);

      return true;
    },

    async processFileDateSigne(e) {
      console.log('filesss dateSigne', e);
      this.isFileDateSigne = e;
      var files = e;
      let fileS3DateSigne = '';
      let fileExt = files.type.split('/');
      var reader = new FileReader();
      reader.readAsDataURL(files);
      reader.onload = (event) => {
        fileS3DateSigne = event.target.result;

        const splitBase64 = reader?.result.split(',');
        this.iconBase64 = splitBase64[1];
        this.iconBase64 = splitBase64[1];
      };
      setTimeout(() => {
        this.payloadDateSigne = {
          name: files?.name,
          type: 12,
          buffer: this.iconBase64,
        };

        console.log('this.payloadDateSigne : ', this.payloadDateSigne);
        const hash =
          Math.random()
            .toString(36)
            .substring(2, 15) +
          Math.random()
            .toString(36)
            .substring(2, 15);

        this.payloadS3DateSigne = {
          image: fileS3DateSigne,
          key: `"piece_date_signe"_${this.currentStructureId}_${hash}${+'.' +
            fileExt[1]}`,
          ID_Structure: this.currentStructureId,
        };
      }, 100);

      reader.onerror = function(error) {
        console.log('Error: ', error);
      };

      // Check size and file type
      if (!(await this.checkFile(files))) return false;

      await this.readFile(files);

      return true;
    },

    async processFileJaofe(e) {
      console.log('filesss verbal', e);
      this.isFileJaofe = e;
      var files = e;
      let fileS3FlieJaofe = '';
      let fileExt = this.file.type.split('/');
      var reader = new FileReader();
      reader.readAsDataURL(files);
      reader.onload = (event) => {
        fileS3FlieJaofe = event.target.result;

        const splitBase64 = reader?.result.split(',');
        this.iconBase64 = splitBase64[1];
      };
      setTimeout(() => {
        this.payloadJaofe = {
          name: this.file?.name,
          type: 18,
          buffer: this.iconBase64,
        };

        console.log('this.payloadJaofe : ', this.payloadJaofe);
        const hash =
          Math.random()
            .toString(36)
            .substring(2, 15) +
          Math.random()
            .toString(36)
            .substring(2, 15);

        this.payloadS3Jaofe = {
          image: fileS3FlieJaofe,
          key: `"piece_jaofe"_${this.currentStructureId}_${hash}${+'.' +
            fileExt[1]}`,
          ID_Structure: this.currentStructureId,
        };
      }, 100);
      reader.onerror = function(error) {
        console.log('Error: ', error);
      };

      // Check size and file type
      if (!(await this.checkFile(files))) return false;

      await this.readFile(files);

      return true;
    },

    async processFileKbis(e) {
      console.log('filesss kbis', e);
      this.isFileKbis = e;
      var files = e;
      let fileS3Kbis = '';
      let fileExt = this.file.type.split('/');
      var reader = new FileReader();
      reader.readAsDataURL(files);
      reader.onload = (event) => {
        fileS3Kbis = event.target.result;

        const splitBase64 = reader?.result.split(',');
        this.iconBase64 = splitBase64[1];
        this.iconBase64 = splitBase64[1];
      };
      setTimeout(() => {
        this.payloadKbis = {
          name: this.file?.name,
          type: 7,
          buffer: this.iconBase64,
        };

        console.log('this.payloadKbis : ', this.payloadKbis);
        const hash =
          Math.random()
            .toString(36)
            .substring(2, 15) +
          Math.random()
            .toString(36)
            .substring(2, 15);

        this.payloadS3Kbis = {
          image: fileS3Kbis,
          key: `"kbis"_${this.currentStructureId}_${hash}${+'.' + fileExt[1]}`,
          ID_Structure: this.currentStructureId,
        };
      }, 100);

      reader.onerror = function(error) {
        console.log('Error: ', error);
      };

      // Check size and file type
      if (!(await this.checkFile(files))) return false;

      await this.readFile(files);

      return true;
    },

    // Chek file size and extesion
    async checkFile(files) {
      if (!this.checkExtension(files)) {
        error_notification(
          'Les formats valides sont les suivants: jpg, jpeg, png et pdf.'
        );
        return false;
      }

      if (!this.checkFileSize(files)) {
        error_notification(
          `le document ${files?.name} dépasse la taille limite de 5 Mo`
        );
        this.isCorrectFileSize = false;
        return false;
      }
      this.isCorrectFileSize = true;
      return true;
    },

    // convert file into a string for upload
    async readFile(files) {
      // Convert file into url transferable string
      if (typeof FileReader === 'function') {
        const reader = new FileReader();

        reader.onload = (event) => {
          this.fileURL = event.target.result;
          return true;
        };
        reader.readAsDataURL(files);
      } else {
        error_notification('Désolé, une erreur est survenue.');
        console.log('FileReader API not supported');
      }
    },
    // Check file size 0-0
    checkFileSize(files) {
      const maxFileSize = 5_000_000; // == 5 MO
      if (files.size > maxFileSize) {
        return false;
      }
      return true;
    },

    // get file extension
    getFileExtension(files) {
      let fileExt = files.type.split('/');
      if (!fileExt[1]) return false;
      return fileExt[1];
    },

    // Check file extension
    checkExtension(files) {
      const allowedFileType = ['jpg', 'jpeg', 'png', 'pdf'];

      let isValid = allowedFileType.find(
        (type) => type == this.getFileExtension(files)
      );
      if (isValid) return true;
      return false;
    },

    // Upload document and emit the respnse object for post processing
    async uploadDocument(payloadS3, typeDocument) {
      let extension = payloadS3.key.split('.').pop();
      console.log('extension', extension);
      const responseUpload = await this.axios
        .post(process.env.VUE_APP_FFME_BACK_URL + '/api/media-upload', payloadS3, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem(process.env.VUE_APP_AUTH_TOKEN_NAME)
          }
        })
        .then(async (response) => {
          if (
            response.data &&
            response.data.location &&
            response.status &&
            response.status === 200
          ) {
            let responseFile = {
              url: response.data.location,
              filename: typeDocument + '.' + extension,
              date: this.$moment().toISOString(),
            };
            console.log('responseFile ---->', responseFile);

            // this.$emit('onFileUploaded', responseFile);

            await this.fileUploaded(responseFile);
            return true;
          } else {
            error_notification('une erreur est survenue, veuillez réessayer');
            this.loading = false;
            console.log('Error on upload');
            return false;
          }
        })
        .catch((error) => {
          this.loading = false;
          error_notification();
          console.log(error.toString());
        });
      return responseUpload;
    },

    async fileUploaded(file) {
      if (!file) return;
      let payload = {
        CheminDocument: file.url,
        NomDocument: file.filename,
        DocumentType: this.documentType,
        ID_Structure: this.currentStructureId,
        ID_Utilisateur: this.currentUser?.id,
        Categorie: 'Documents kyc',
      };
      console.log('fileUploaded payload', payload);
      try {
        const response = await this.insertDocument(payload);
        console.log('fileUploaded response atooooo', payload);
      } catch (e) {
        error_notification(
          'Oups, une erreur est survenue lors du téléchargement du document...'
        );
      }
    },

    async submit() {
      this.loading = true;
      if (!this.isEA) {
        const promise1 = await this.uploadDocument(
          this.payloadS3PieceIdentity,
          'piece_identite'
        );
        const promise2 = await this.uploadDocument(
          this.payloadS3ProcesVerbal,
          'piece_proces_verbal'
        );

        const promise3 = await this.uploadDocument(
          this.payloadS3DateSigne,
          'piece_date_signe'
        );

        const promise4 = await this.uploadDocument(
          this.payloadS3Jaofe,
          'piece_jaofe'
        );

        Promise.all([promise1, promise2, promise3, promise4]).then(
          async (res) => {
            const allTrue = res.filter((r) => r === true);
            console.log('allTrue', allTrue);
            if (allTrue.length === 4) {
              await this.callUploadDocument(this.payloadPieceIdentity);
              await this.callUploadDocument(this.payloadProcesVerbal);
              await this.callUploadDocument(this.payloadDateSigne);
              await this.callUploadDocument(this.payloadJaofe);

              success_notification('Document(s) envoyé(s)');
              this.$emit('onNextStep');
              this.loading = false;
            } else {
              this.loading = false;
              error_notification('Une erreur est survenue...');
            }
          }
        );
      } else {
        //IS EA
        const promise1 = await this.uploadDocument(
          this.payloadS3PieceIdentityEA,
          'actionnariat_societe'
        );

        const promise2 = await this.uploadDocument(
          this.payloadS3DateSigne,
          'piece_date_signe'
        );

        const promise3 = await this.uploadDocument(this.payloadS3Kbis, 'kbis');

        Promise.all([promise1, promise2, promise3]).then(async (res) => {
          const allTrue = res.filter((r) => r === true);
          console.log('allTrue', allTrue);
          if (allTrue.length === 3) {
            await this.callUploadDocument(this.payloadPieceIdentityEA);
            await this.callUploadDocument(this.payloadDateSigne);
            await this.callUploadDocument(this.payloadKbis);

            success_notification('Documents envoyés');
            this.$emit('onNextStep');
            this.loading = false;
          } else {
            this.loading = false;
            error_notification('Une erreur est survenue...');
          }
        });
      }
    },

    async callUploadDocument(payload) {
      try {
        const fetch = require('node-fetch');

        const response = await fetch(
            `${process.env.VUE_APP_FFME_BACK_URL}/api/kyclemonway/uploadDocument`,
            {
              method: 'POST',
              headers: {
                'Authorization': "Bearer " + localStorage.getItem(process.env.VUE_APP_AUTH_TOKEN_NAME),
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({data : payload, accountId: this.currentStructureId}),
            }
        );
        const json = await response.json();
        // console.log("response call upload====>", json);
        if (json.uploadDocument) {
          // success_notification('Document envoyé');
          return { ok: true };
          // this.$emit('onNextStep');
        } else if (
          json.error?.message ==
          'A document of the same type is already waiting for review'
        ) {
          error_notification(`Document déjà téléversé`);
          return { ok: false };
        } else if (json.error?.message == 'DUPLICATED_DOCUMENT_FOUND') {
          error_notification(`Document déjà téléversé`);
          return { ok: false };
        }
        // error_notification(`Veuillez réessayer plus tard`);
      } catch (error) {
        console.log('erro r:', error);
        // error_notification(`${error}`);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.stepper-title {
  font-family: 'Roboto';
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: -1px;
  text-align: left;
}

.icon-style {
  width: 20px;
  height: 20px;
  position: absolute;
  right: 0;
}

.d-contents {
  display: contents !important;
}

.h-0 {
  height: 0px !important;
}

.stepper-menu-title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
}

.stepper-menu-subtitle {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
}

.stepper-action {
  //position: absolute;
  //bottom: 0;
  //right: 0;
}

.stepper-info {
  background: rgba(8, 43, 110, 0.1) !important;
  //border: 2px solid #082B6E;
}

.stepper-info-text {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  /* or 143% */

  display: flex;
  align-items: center;

  /* Primary/Royal */

  color: #082b6e;

  border-left: 2px solid #082b6e !important;
}

.custom-label {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */

  display: flex;
  align-items: center;

  /* Primary/Dark */

  color: #000000;
}
.select-attachement-text {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #000000;
  display: flex;
  align-items: center;
}
</style>
