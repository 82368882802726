<template>
  <div>
    <v-stepper class="kyc-stepper-container" v-model="etape" alt-labels>
      <v-stepper-header class="kyc-stepper-header mb-5">
        <v-stepper-step :complete="etape > 1" step="1"
          >Vérification KYC</v-stepper-step
        >
        <v-divider></v-divider>
        <v-stepper-step :complete="etape > 2" step="2"
          >Documents à joindre</v-stepper-step
        >
        <v-divider></v-divider>
        <v-stepper-step step="3">Suivi du dossier</v-stepper-step>
      </v-stepper-header>

      <v-progress-linear
        v-if="loading"
        indeterminate
        color="cyan"
      ></v-progress-linear>

      <v-stepper-items class="position-relative">
        <v-stepper-content class="kyc-stepper-body position-relative" step="1">
          <Etape1 @onNextStep="etape += 1" />
        </v-stepper-content>

        <v-stepper-content step="2" no-gutters>
          <Etape2 @onNextStep="etape += 1" />
        </v-stepper-content>

        <v-stepper-content step="3">
          <Etape3 />
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';

import Etape1 from '@/components/User/Kyc/VerificationKyc_Step1.vue';
import Etape2 from '@/components/User/Kyc/VerificationKyc_Step2.vue';
import Etape3 from '@/components/User/Kyc/VerificationKyc_Step3.vue';
import {
  error_notification,
  success_notification,
} from '@/plugins/notifications';
import fetch from "node-fetch";

export default {
  components: { Etape1, Etape2, Etape3 },

  data: () => ({
    etape: 1,
    loading: false,
    users: [],
    user: {},
    account: null,
    getDocKyc: [],
    allDocument: [],
  }),

  async created() {
    await this.setData();
  },

  computed: {
    ...mapGetters('user', ['currentUser']),
    ...mapGetters('structure', [
      'currentStructureId',
      'currentStructure',
      'currentStructureType',
    ]),

    isEA() {
      return (
        this.currentStructureType.SlugTypeStructure ==
        'club_etablissement_affilie'
      );
    },
  },

  methods: {
    ...mapActions('utilisateurs', [
      'getUserPaymentAccount',
      'getUtilisateurDocumentKyc',
    ]),

    async setData() {
      const getAllPaymentAccount = await this.getUserPaymentAccount();
      const getDocKyc = await this.getUtilisateurDocumentKyc(
        this.currentUser?.id
      );
      const docKycStructure = getDocKyc.filter(
        (doc) => doc.ID_Structure === this.currentStructureId
      );

      console.log(' getDocKyc firstttt', getDocKyc);
      console.log(' docKycStructure firstttt', docKycStructure);

      // if (getAllPaymentAccount) {
      //   this.haveAccount = getAllPaymentAccount.find(
      //     (account) => account.ID_Structure === this.currentStructureId
      //   );
      // }

      await this.getRetrieveAccount();
      await this.checkDocumentsFromKyc();
    },

    async getRetrieveAccount() {
      try {
        const fetch = require('node-fetch');
        const response = await fetch(
            `${process.env.VUE_APP_FFME_BACK_URL}/api/kyclemonway/${this.currentStructureId}`,
            {
              method: 'GET',
              headers: {
                'Authorization': "Bearer " + localStorage.getItem(process.env.VUE_APP_AUTH_TOKEN_NAME),
                'Content-Type': 'application/json',
              },
            }
        );

        const json = await response.json();
        console.log('response getretrieveAccount====>', json);

        if (json.account) {
          console.log('mandalooo json getretrieveAccount ====>');
          this.account = json?.account;
          return { ok: true };
        } else {
          return { ok: false };
        }
      } catch (error) {
        console.log('erro r:', error);
      }
    },
    async checkDocumentsFromKyc() {
      this.status = [];
      try {
        const fetch = require('node-fetch');
        const headers = {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': 'Content-Type',
        };

        const response = await fetch(
            `${process.env.VUE_APP_FFME_BACK_URL}/api/kyclemonway/${this.currentStructureId}/documents`,
            {
              method: 'GET',
              headers: {
                'Authorization': "Bearer " + localStorage.getItem(process.env.VUE_APP_AUTH_TOKEN_NAME),
                'Content-Type': 'application/json',
              },
            }
        );
        const json = await response.json();
        // console.log('json response ----->', json);

        this.allDocument = json.documents;
        console.log('this.haveAccount resp----->', this.account);

        if (json && json.documents) {
          if (!this.isEA) {
            if (this.account && this.allDocument.length >= 4) {
              this.etape = 3;
            } else if (this.account && this.allDocument.length < 4) {
              this.etape = 2;
            } else {
              this.etape = 1;
            }
          } else {
            if (this.account && this.allDocument.length >= 3) {
              this.etape = 3;
            } else if (this.account && this.allDocument.length < 3) {
              this.etape = 2;
            } else {
              this.etape = 1;
            }
          }
        }

        console.log('this.allDocument first----->', this.allDocument);
        // if (json.error?.message == 'Wrong IP format') {
        //   error_notification();
        // }
      } catch (error) {
        console.log('erro r:', error);
      }
    },

  },

  props: {},
};
</script>

<style lang="scss" scopped>
.kyc-stepper-container {
  box-shadow: none !important;

  .kyc-stepper-header {
    box-shadow: 2px 4px 8px -2px #082b6e40 !important;
  }
}
.kyc-stepper-body {
  box-shadow: 2px 4px 8px -2px #082b6e40 !important;
}
.v-stepper__step__step {
  height: 32px;
  width: 32px;
  left: 15px;
  top: 0px;
  border-radius: 16px;

  &.primary {
    background: #082b6e !important;
  }
}

.v-stepper__wrapper {
  height: 100% !important;
}
.v-stepper__label {
  text-align: center !important;
}
.v-stepper__content {
  padding: 0 !important;
}
</style>
box-shadow: 2px 4px 8px -2px #082B6E40;
