<template>
  <v-row class="position-relative">
    <v-expansion-panels class="mt-1" v-model="panel">
      <v-expansion-panel>
        <v-expansion-panel-header class="filtre-panel">
          <span v-if="panel === 0">Masquer les filtres</span>
          <span v-else>Afficher les filtres</span>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="py-5 backgrounded">
          <ValidateForm :showFieldObl="false" :key="refreshForm">
            <v-row>
              <v-col cols="12" class="d-flex">
                <!-- <v-btn depressed class="text-capitalize mr-3 filter-btn"
                  >Période de transaction</v-btn
                > -->

                <v-expansion-panels
                  class="text-capitalize mr-3 panel-date"
                  v-model="panelDate"
                >
                  <v-expansion-panel class="text-capitalize">
                    <v-expansion-panel-header
                      disable-icon-rotate
                      class="filter-btn"
                    >
                      {{
                        startDate && endDate
                          ? `Période de transaction : ${$moment(
                              startDate
                            ).format('DD/MM/YYYY')} - ${$moment(endDate).format(
                              'DD/MM/YYYY'
                            )}`
                          : 'Période de transaction'
                      }}
                      <template v-slot:actions>
                        <v-icon @click="resetFilterDate">mdi-close</v-icon>
                      </template>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <datePicker
                        v-model="startDate"
                        rules="date"
                        label="Date de début"
                        withIcon="false"
                      />
                    </v-expansion-panel-content>
                    <v-expansion-panel-content>
                      <datePicker
                        v-model="endDate"
                        rules="date"
                        label="Date de fin"
                        withIcon="false"
                      />
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>

                <v-btn
                  depressed
                  class="text-capitalize mr-3 filter-btn"
                  @click="filterAmount"
                  >Montant</v-btn
                >
                <v-btn
                  depressed
                  class="text-capitalize mr-3 filter-btn"
                  @click="filterTransmitter"
                  >Emetteur</v-btn
                >
                <v-btn
                  depressed
                  class="text-capitalize mr-3 filter-btn"
                  @click="filterStatus"
                  >Status</v-btn
                >
              </v-col>
              <v-col cols="6" class="d-flex justify-content-start">
                <!-- <span class="clear-filter p-1 px-5" @click="filterDate"
                  >Effacer les filtres</span
                > -->
                <Button
                  :color="'#082B6E'"
                  class="btn-validation"
                  @click="clearAllFilter"
                  rounded
                  outlined
                  height="35"
                  type="primary"
                >
                  Effacer les filtres
                </Button>
              </v-col>
              <v-col cols="6" class="d-flex justify-content-end">
                <v-btn
                  type="submit"
                  class="btn btn-primary"
                  @click="exportSearch"
                  >Exporter la liste</v-btn
                >
                <JsonExcel
                  class="d-none"
                  ref="csvBtn"
                  :data="json_data"
                  :fields="json_fields"
                  name="filename.xls"
                  >Exporter</JsonExcel
                >
              </v-col>
            </v-row>
          </ValidateForm>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <UserListMoneyOuts :headers="headers" :loading="loading" />
  </v-row>
</template>

<script>
import UserListMoneyOuts from '@/components/User/List/User__List__MoneyOuts.vue';
import ValidateForm from '@/components/utils/form.vue';
import { bus } from '@/main';
import Button from '../../utils/button.vue';
import JsonExcel from 'vue-json-excel';
import { mapGetters, mapState } from 'vuex';
export default {
  components: {
    UserListMoneyOuts,
    ValidateForm,
    Button,
    JsonExcel,
    DatePicker: () =>
      import(
        /* webpackChunkName: "input__datepicker" */ '@/components/utils/datePicker.vue'
      ),
  },
  data() {
    return {
      panel: 0,
      panelDate: '',
      refreshForm: 0,
      headers: [
        { text: 'Date', value: 'Date', sortable: true },
        { text: 'Catégorie', value: 'Category', sortable: true },
        { text: 'Montant', value: 'Amount', sortable: true },
        { text: 'Emetteur', value: 'Transmitter', sortable: true },
        { text: 'Statut', value: 'Status', sortable: true },
      ],
      startDate: null,
      endDate: null,
      json_fields: {
        Date: 'date',
        Catégorie: 'category',
        Montant: 'amount',
        Emetteur: 'transmitter',
        Statut: 'status',
      },
      json_data: [],
    };
  },

  created() {
    console.log('PM TRANSACTION currentTab');
  },

  computed: {
    ...mapState({
      moneyOutsKyc: (state) => state.structure.moneyOuts,
    }),
  },

  mounted() {
    this.$watch(
      (vm) => [vm.startDate, vm.endDate],
      (val) => {
        const start = new Date(val[0]).getTime();
        const end = new Date(val[1]).getTime();

        if (val[0] !== null && val[1] !== null) {
          bus.$emit('search-filter-date', start, end);
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },

  methods: {
    filterDate() {
      bus.$emit('set-filter-date');
    },
    filterAmount() {
      bus.$emit('set-filter-amount');
    },
    filterTransmitter() {
      bus.$emit('set-filter-transmitter');
    },
    filterStatus() {
      bus.$emit('set-filter-status');
    },

    resetFilterDate() {
      this.startDate = '';
      this.endDate = '';
      this.UpdateDateStart(this.startDate);
      this.UpdateDateEnd(this.endDate);
    },
    UpdateDateStart(date) {
      this.startDate = date;
      this.refreshForm++;
    },
    UpdateDateEnd(date) {
      this.endDate = date;
      this.refreshForm++;
    },

    clearAllFilter() {
      this.resetFilterDate();
      this.filterDate();
    },

    formatDate(value) {
      if (!value) return '';
      const milliseconds = value * 1000;
      const dateObject = new Date(milliseconds);
      const frDateFormat = dateObject.toLocaleString('fr-FR');
      return frDateFormat;
    },

    async exportSearch() {
      this.moneyOutsKyc.map((data) => {
        console.log('dataaaa csv', data);
        let csvData = {
          date: data.transactionP2P
            ? this.formatDate(data.transactionP2P.date)
            : data.transactionIn
            ? this.formatDate(data.transactionIn.date)
            : data.transactionOut
            ? this.formatDate(data.transactionOut.date)
            : '-',
          category: data.transactionP2P
            ? 'P2P'
            : data.transactionIn
            ? 'Money-In'
            : data.transactionOut
            ? 'Money-Out'
            : '-',
          amount: data.transactionP2P
            ? data.transactionP2P.creditAmount / 100 + ' €'
            : data.transactionIn
            ? data.transactionIn.creditAmount / 100 + ' €'
            : data.transactionOut
            ? data.transactionOut.debitAmount / 100 + ' €'
            : '-',
          transmitter: data.transactionP2P
            ? data.transactionP2P.senderAccountId
            : data.transactionIn
            ? '-'
            : data.transactionOut
            ? data.transactionOut.senderAccountId
            : '-',
          status:
            data.transactionP2P?.status === 3
              ? 'Accepté'
              : data.transactionP2P?.status === 4
              ? 'En atttente'
              : data.transactionP2P?.status === 7
              ? 'Annulé'
              : data.transactionP2P?.status === 16
              ? 'En attente de validation'
              : data.transactionIn?.status === 0
              ? 'Accepté'
              : data.transactionIn?.status === 4
              ? 'En atttente'
              : data.transactionIn?.status === 7
              ? 'Annulé'
              : data.transactionIn?.status === 16
              ? 'En attente de validation'
              : data.transactionOut?.status === 0
              ? 'Accepté'
              : '-',
        };
        this.json_data.push(csvData);
      });

      // this.loading = true;
      setTimeout(() => {
        this.$refs.csvBtn.$el.click();
      }, 100);
      // this.loading = false;
    },
  },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.filtre-panel {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
  /* identical to box height, or 160% */

  letter-spacing: 1px;
  text-transform: uppercase;

  /* Primary/Dark */
  background: #f8f9fb;
  color: #000000;
}
.filter-btn {
  background: #ffffff !important;
  box-shadow: 2px 4px 8px -2px rgba(8, 43, 110, 0.25);
  border-radius: 0% !important;
  padding: 8px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  /* identical to box height */

  display: flex;
  align-items: center;

  /* Primary/Dark */

  color: #000000;
}
.backgrounded {
  background: #ebeef3;
}
.clear-filter {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  /* identical to box height */
  border: 1px solid rgba(8, 43, 110, 0.25);
  border-radius: 30px;
  display: flex;
  align-items: center;

  /* Primary/Blue */

  color: #082b6e;
}
.stepper-table-head {
  background: #39558b !important;

  .table-content {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #ffffff;
  }
}
.stepper-table-content {
  //styleName: Body/14px Deci;
  font-family: 'Roboto';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  border-top: 0px !important;
  padding-bottom: 0px !important;
}
.status-table-content {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */

  text-align: center;

  color: #00be76;
  background: rgba(0, 190, 118, 0.15);
  border-radius: 6px;

  &.annuler {
    background: rgba(255, 0, 0, 0.15);
    color: #ff0000;
  }
}
.category-table-content {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */

  text-align: center;

  /* Primary/Royal */

  color: #082b6e;
  border: 1px solid rgba(8, 43, 110, 0.25);
  border-radius: 30px;
}
.icon-color {
  &.first-blue {
    color: #1c3d7a !important;
  }
}
.paginateur {
  background: #f8f9fb;
}
.action-paginate {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
  /* identical to box height, or 160% */

  display: flex;
  align-items: center;
  letter-spacing: 1px;
  text-transform: uppercase;

  /* Royal/100 */

  color: #020918;
}
.detail-paginate {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */

  text-align: center;

  /* Primary/Dark */

  color: #000000;
}

.v-btn:not(.v-btn--round).v-size--default {
  height: 46px;
  min-width: 64px;
  padding: 0 16px;
}
.panel-date {
  width: 445px !important;
}
</style>
