<template>
  <v-row
    no-gutters
    class="gestion_des_competitions__dashboard-layout"
    :class="{ loading: loading }"
  >
    <v-col cols="12" lg="8" class="mx-auto">
      <v-row style="height: 100%;">
        <v-col cols="12" class="p-4 licences-adherents-content">
          <v-row style="height: 100%;">
            <v-col cols="6">
              <dashboardCard
                title_card="Mes compétitions"
                module_name="gestion_des_competitions__mes_competitions"
              />
            </v-col>
            <v-col cols="6">
              <dashboardCard
                title_card="Saisir une compétition"
                module_name="gestion_des_competitions__saisir_une_competition"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import DashboardCard from "@/components/Common/DashboardCard"
export default {

  name: "GestionDesCompetitions__Dashboard",

  components: {DashboardCard},

  data: () => ({
    loading: true,
  }),
  async created() {
    await this.setAppTitle({ title: "Gestion compétitions" });
    this.loading = false;
  },

  methods: {
    ...mapActions("app", ["setAppTitle"]),
  },
};
</script>

<style lang="scss">
.gestion_des_competitions__dashboard-layout {
  position: relative;
  height: calc(100vh - 80px);
  &.loading {
    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.8);
    }
  }
}
</style>
