<template>
  <div class="h-100 position-relative">
    <v-row>
      <v-col cols="12" class="pb-0">
        <h1 class="stepper-title px-3">Etape 3 - Suivi du dossier</h1>
      </v-col>
      <v-col cols="12" class="py-0 d-flex justify-content-start">
        <v-btn
          type="submit"
          class="btn btn-primary m-3"
          :loading="loading"
          @click="refreshTable"
          >Actualiser pour voir le suivi du dossier
          <v-icon right>
            mdi-refresh
          </v-icon></v-btn
        >
      </v-col>
      <input
        style="display: none;"
        accept=".jpg,.jpeg,.png,.pdf"
        type="file"
        ref="fileInput"
        @change="processFileUpload"
      />
      <v-col cols="12">
        <table class="table" v-if="showTable">
          <thead class="thead-dark"></thead>
          <tbody>
            <tr class="stepper-table-head">
              <td class="table-content">Date du téléversement</td>
              <td class="table-content">Type de document</td>
              <!-- <td class="table-content">Nom</td> -->
              <td class="table-content">Statut</td>
              <td class="table-content">Actions</td>
            </tr>
            <tr v-for="(stat, i) in status" :key="i">
              <td class="stepper-table-content">{{ stat.date }}</td>
              <td class="stepper-table-content">{{ stat.name }}</td>
              <!-- <td class="stepper-table-content">{{ stat.slug }}</td> -->
              <td class="stepper-table-content d-flex">
                <span :class="`status ${stat.class} mr-1`"></span>
                <span> {{ stat.value }} </span>
              </td>
              <td class="stepper-table-content">
                <div
                  class="d-flex w-100 justify-content-center align-items-center"
                >
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn icon :disabled="stat.disabled" v-on="on">
                        <a
                          style="color: #fff;"
                          :href="stat.download"
                          target="_blank"
                        >
                          <svg
                            style="width: 20px; height: 20px;"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                          >
                            <path
                              :fill="stat.disabled ? '#B5BFD3' : '#082B6E'"
                              d="M432,320H400a16,16,0,0,0-16,16V448H64V128H208a16,16,0,0,0,16-16V80a16,16,0,0,0-16-16H48A48,48,0,0,0,0,112V464a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V336A16,16,0,0,0,432,320ZM488,0h-128c-21.37,0-32.05,25.91-17,41l35.73,35.73L135,320.37a24,24,0,0,0,0,34L157.67,377a24,24,0,0,0,34,0L435.28,133.32,471,169c15,15,41,4.5,41-17V24A24,24,0,0,0,488,0Z"
                            /></svg
                        ></a>
                      </v-btn>
                    </template>
                    <span>Télécharger le fichier</span>
                  </v-tooltip>

                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        icon
                        :disabled="stat.disabled"
                        @click="reUploadFile(stat.data)"
                        v-on="on"
                      >
                        <svg
                          style="width: 20px; height: 20px;"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 576 512"
                        >
                          <path
                            :fill="stat.disabled ? '#B5BFD3' : '#082B6E'"
                            d="M402.6 83.2l90.2 90.2c3.8 3.8 3.8 10 0 13.8L274.4 405.6l-92.8 10.3c-12.4 1.4-22.9-9.1-21.5-21.5l10.3-92.8L388.8 83.2c3.8-3.8 10-3.8 13.8 0zm162-22.9l-48.8-48.8c-15.2-15.2-39.9-15.2-55.2 0l-35.4 35.4c-3.8 3.8-3.8 10 0 13.8l90.2 90.2c3.8 3.8 10 3.8 13.8 0l35.4-35.4c15.2-15.3 15.2-40 0-55.2zM384 346.2V448H64V128h229.8c3.2 0 6.2-1.3 8.5-3.5l40-40c7.6-7.6 2.2-20.5-8.5-20.5H48C21.5 64 0 85.5 0 112v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V306.2c0-10.7-12.9-16-20.5-8.5l-40 40c-2.2 2.3-3.5 5.3-3.5 8.5z"
                          />
                        </svg>
                      </v-btn>
                    </template>
                    <span>Téléverser un nouveau fichier</span>
                  </v-tooltip>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
// import Loading from '@/components/LoadingIndicator.vue';

import {
  error_notification,
  success_notification,
} from '@/plugins/notifications';
import fetch from "node-fetch";

export default {
  // components: { Loading },
  data: () => ({
    // isEa: true,
    showTable: false,
    documentType: null,
    allDocument: [],
    allDocumentType: [
      {
        name: 'Passeport (Union Européenne)',
        slug: 'piece_identite',
        value: 3,
      },
      {
        name: 'Passeport (hors Union Européenne)',
        slug: 'piece_identite',
        value: 4,
      },
      { name: "Carte d'identité", slug: 'piece_identite', value: 0 },
      { name: 'Titre de séjour', slug: 'piece_identite', value: 5 },
      { name: 'Permis de conduire', slug: 'piece_identite', value: 11 },
      {
        name: 'Procès-verbal association',
        slug: 'piece_proces_verbal',
        value: 17,
      },
      { name: 'Statuts datés et signés', slug: 'piece_date_signe', value: 12 },
      { name: 'Parution au JOAFE', slug: 'piece_jaofe', value: 18 },
    ],
    allDocumentTypeEA: [
      {
        name: 'Table de capitalisation',
        slug: 'actionnariat_societe',
        value: 19,
      },
      {
        name: 'Formulaire type DBE-S1',
        slug: 'actionnariat_societe',
        value: 20,
      },
      { name: 'Statuts datés et signés', slug: 'piece_date_signe', value: 12 },
      { name: 'KBIS', slug: 'kbis', value: 7 },
    ],
    allStatus: [
      {
        name: 'Document mis en attente, en attente d\'un autre document.',
        class: 'waiting',
        editable: true,
        value: 0,
      },
      {
        name: 'Reçu, nécessite une validation manuelle.',
        class: 'waiting',
        editable: true,
        value: 1,
      },
      { name: 'Valide', class: 'accepted', editable: true, value: 2 },
      { name: 'Invalide', class: 'declined', editable: false, value: 3 },
      {
        name: 'Invalide : Illisible par un humain (cadrage, flou, taille…).',
        class: 'declined',
        editable: false,
        value: 4,
      },
      {
        name: 'Invalide : Date d’expiration dépassé',
        class: 'declined',
        editable: false,
        value: 5,
      },
      {
        name: 'Invalide : Mauvais type de document',
        class: 'declined',
        editable: false,
        value: 6,
      },
      {
        name: 'Invalide : Le nom sur le document ne correspond pas aux informations connues',
        class: 'declined',
        editable: false,
        value: 7,
      },
      {
        name: 'Invalide : Doublon de document',
        class: 'declined',
        editable: false,
        value: 8,
      },
    ],
    status: [],
    accountKyc: '',
    resultStatus: false,
    getDocKyc: [],
    fileSelected: '',
    docS3Selected: '',
    currentDocSelected: '',
    loading: false,
    showRefreshBtn: true,
  }),

  created() {
    this.setData();
  },

  computed: {
    ...mapGetters('user', ['currentUser']),
    ...mapGetters('structure', ['currentStructureId', 'currentStructureType']),
    account() {
      return this.accountKyc;
    },
    isEA() {
      return (
        this.currentStructureType.SlugTypeStructure ==
        'club_etablissement_affilie'
      );
    },
  },

  methods: {
    ...mapActions('utilisateurs', [
      'getUserPaymentAccount',
      'getUserPaymentAccountStructure',
      'getUtilisateurDocumentKyc',
    ]),
    ...mapActions('basics', ['updateDocumentKyc']),

    async setData() {
      this.getDocKyc = await this.getUtilisateurDocumentKyc(
        this.currentUser.id
      );
      // console.log('getDocKyc', this.getDocKyc);

            this.getAllPaymentAccount = await this.getUserPaymentAccountStructure(
        this.currentStructureId
      );
      // const haveAccount = getAllPaymentAccount.find(
      //   (account) => account.ID_Structure == this.currentStructureId
      // );
      this.accountKyc = this.getAllPaymentAccount[0]?.NomCompte;
      console.log('this.accountKyc', this.accountKyc);
      // if (this.accountKyc) {
      //   await this.checkStatus();
      // }
    },

    async refreshTable() {
      this.loading = true;
      await this.checkStatus();
      this.showTable = true;
    },

    async checkStatus() {
      this.status = [];
      try {
        const fetch = require('node-fetch');
        const response = await fetch(
            `${process.env.VUE_APP_FFME_BACK_URL}/api/kyclemonway/${this.currentStructureId}/documents`,
            {
              method: 'GET',
              headers: {
                'Authorization': "Bearer " + localStorage.getItem(process.env.VUE_APP_AUTH_TOKEN_NAME),
                'Content-Type': 'application/json',
              },
            }
        );
        const json = await response.json();
        this.allDocument = json.documents;
        console.log('this.allDocument----->', this.allDocument);
        if (!this.isEA) {
          let unique = this.allDocument.filter(
            (v, i, a) => a.findLastIndex((v2) => v2.type === v.type) === i
          );
          unique?.map((alldoc) => {
            var document = this.allDocumentType.find(
              (document_type) => document_type.value == alldoc.type
            );
            // console.log('document !!!!', document);
            var status = this.allStatus.find(
              (stat) => stat.value == alldoc.status
            );
            // console.log('status !!!!', status);

            var docS3 = this.getDocKyc.find(
              (kyc) => kyc.NomDocument?.split('.')[0] == document.slug
            );
            this.docS3Selected = { ...docS3, ...document };
            // console.log('this.docS3Selected !!!!', this.docS3Selected);

            var statusTemp = {
              name: document?.name,
              value: status?.name,
              class: status?.class,
              disabled: status?.editable,
              slug: docS3?.NomDocument,
              download: docS3?.CheminDocument,
              date: this.$moment(docS3?.Z_DateModification).format(
                'DD/MM/YYYY'
              ),
              data: { ...docS3, ...document },
            };
            if ([3, 4, 5, 6, 7, 8].includes(status.value) && alldoc?.comment !== '') {
              statusTemp.value += ` (${alldoc?.comment})`;
            }
            this.status.push(statusTemp);
            console.log('this.status !!!!', this.status);

            this.resultStatus = true;
            this.loading = false;
            // this.showRefreshBtn = false;
          });
        } else {
          let unique = this.allDocument.filter(
            (v, i, a) => a.findLastIndex((v2) => v2.type === v.type) === i
          );
          unique?.map((alldoc) => {
            var document = this.allDocumentTypeEA.find(
              (document_type) => document_type.value == alldoc.type
            );
            // console.log('document !!!!', document);
            var status = this.allStatus.find(
              (stat) => stat.value == alldoc.status
            );
            // console.log('status !!!!', status);

            var docS3 = this.getDocKyc.find(
              (kyc) => kyc.NomDocument?.split('.')[0] == document.slug
            );
            this.docS3Selected = { ...docS3, ...document };
            // console.log('this.docS3Selected !!!!', this.docS3Selected);

            var statusTemp = {
              name: document?.name,
              value: status?.name,
              class: status?.class,
              disabled: status?.editable,
              slug: docS3?.NomDocument,
              download: docS3?.CheminDocument,
              date: this.$moment(docS3?.Z_DateModification).format(
                'DD/MM/YYYY'
              ),
              data: { ...docS3, ...document },
            };
            if ([3, 4, 5, 6, 7, 8].includes(status.value) && alldoc?.comment !== '') {
              statusTemp.value += ` (${alldoc?.comment})`;
            }
            this.status.push(statusTemp);
            console.log('this.status !!!!', this.status);

            this.resultStatus = true;
            this.loading = false;
            // this.showRefreshBtn = false;
          });
        }
      } catch (error) {
        console.log('erro r:', error);
      }
    },

    async processFileUpload(e) {
      var files = e.target.files || e.dataTransfer.files;
      this.fileSelected = files[0];
      console.log(' this.fileSelected', this.fileSelected);
      console.log('this.docS3Selected', this.docS3Selected);
      console.log('this.currentDocSelected', this.currentDocSelected);
      let fileExt = this.fileSelected.type.split('/');
      console.log('fileExt fileExt', fileExt);

      var reader = new FileReader();
      let fileS3 = '';
      reader.readAsDataURL(this.fileSelected);
      reader.onload = (event) => {
        fileS3 = event.target.result;
        // this.payloadS3ProcesVerbal = event.target.result;

        const splitBase64 = reader?.result.split(',');
        this.iconBase64 = splitBase64[1];
      };
      let docS3ID = this.currentDocSelected?.id;
      let docS3Selected = this.currentDocSelected?.NomDocument.split('.')[0];
      console.log('docS3Selected------------>', docS3Selected);
      let payloadDoc = {};
      let payloadDocS3 = {};
      let saveDocument = '';

      setTimeout(async () => {
        const hash =
          Math.random()
            .toString(36)
            .substring(2, 15) +
          Math.random()
            .toString(36)
            .substring(2, 15);

        switch (docS3Selected) {
          case 'piece_identite':
            payloadDoc = {
              name: this.fileSelected?.name,
              type: this.currentDocSelected?.value,
              buffer: this.iconBase64,
            };
            payloadDocS3 = {
              image: fileS3,
              key: `"piece_identite"_${this.currentStructureId}_${hash}.${fileExt[1]}`,
              ID_Structure: this.currentStructureId,
            };
            saveDocument = await this.callUploadDocument(payloadDoc);
            if (saveDocument.ok) {
              let response = await this.uploadDocument(
                payloadDocS3,
                docS3ID,
                'piece_identite'
              );
              console.log('await this.checkStatus();', response);

              if (response) {
                console.log('await this.checkStatus();');
                await this.checkStatus();
              }
            }
            break;

          case 'actionnariat_societe':
            payloadDoc = {
              name: this.fileSelected?.name,
              type: this.currentDocSelected?.value,
              buffer: this.iconBase64,
            };
            payloadDocS3 = {
              image: fileS3,
              key: `"actionnariat_societe"_${this.currentStructureId}_${hash}.${fileExt[1]}`,
              ID_Structure: this.currentStructureId,
            };
            saveDocument = await this.callUploadDocument(payloadDoc);
            if (saveDocument.ok) {
              let response = await this.uploadDocument(
                payloadDocS3,
                docS3ID,
                'actionnariat_societe'
              );
              console.log('await this.checkStatus();', response);

              if (response) {
                console.log('await this.checkStatus();');
                await this.checkStatus();
              }
            }
            break;

          case 'piece_proces_verbal':
            payloadDoc = {
              name: this.fileSelected?.name,
              type: this.currentDocSelected?.value,
              buffer: this.iconBase64,
            };
            payloadDocS3 = {
              image: fileS3,
              key: `"piece_proces_verbal"_${this.currentStructureId}_${hash}.${fileExt[1]}`,
              ID_Structure: this.currentStructureId,
            };
            saveDocument = await this.callUploadDocument(payloadDoc);
            if (saveDocument.ok) {
              await this.uploadDocument(
                payloadDocS3,
                docS3ID,
                'piece_proces_verbal'
              );
            }
            break;

          case 'piece_date_signe':
            payloadDoc = {
              name: this.fileSelected?.name,
              type: this.currentDocSelected?.value,
              buffer: this.iconBase64,
            };
            payloadDocS3 = {
              image: fileS3,
              key: `"piece_date_signe"_${this.currentStructureId}_${hash}.${fileExt[1]}`,
              ID_Structure: this.currentStructureId,
            };
            console.log('piece_date_signe payload', payloadDoc);
            saveDocument = await this.callUploadDocument(payloadDoc);
            console.log('piece_date_signe saveDocument', saveDocument);

            if (saveDocument.ok) {
              const response = await this.uploadDocument(
                payloadDocS3,
                docS3ID,
                'piece_date_signe'
              );
              if (response) {
                console.log('await this.checkStatus();');
                await this.checkStatus();
              }
            }
            break;

          case 'piece_jaofe':
            payloadDoc = {
              name: this.fileSelected?.name,
              type: this.currentDocSelected?.value,
              buffer: this.iconBase64,
            };
            payloadDocS3 = {
              image: fileS3,
              key: `"piece_jaofe"_${this.currentStructureId}_${hash}.${fileExt[1]}`,
              ID_Structure: this.currentStructureId,
            };
            saveDocument = await this.callUploadDocument(payloadDoc);
            if (saveDocument.ok) {
              await this.uploadDocument(payloadDocS3, docS3ID, 'piece_jaofe');
            }
            break;

          case 'kbis':
            payloadDoc = {
              name: this.fileSelected?.name,
              type: this.currentDocSelected?.value,
              buffer: this.iconBase64,
            };
            payloadDocS3 = {
              image: fileS3,
              key: `"kbis"_${this.currentStructureId}_${hash}.${fileExt[1]}`,
              ID_Structure: this.currentStructureId,
            };
            console.log('kbis payload', payloadDoc);
            saveDocument = await this.callUploadDocument(payloadDoc);
            console.log('kbis saveDocument', saveDocument);

            if (saveDocument.ok) {
              const response = await this.uploadDocument(
                payloadDocS3,
                docS3ID,
                'kbis'
              );
              if (response) {
                console.log('await this.checkStatus();');
                await this.checkStatus();
              }
            }
            break;

          default:
            break;
        }
        console.log('payloadDoc ---------', payloadDoc);
      }, 100);

      reader.onerror = function(error) {
        console.log('Error: ', error);
      };

      // Check size and file type
      if (!(await this.checkFile())) return false;

      await this.readFile();

      return true;
    },
    // convert file into a string for upload
    async readFile() {
      // Convert file into url transferable string
      if (typeof FileReader === 'function') {
        const reader = new FileReader();

        reader.onload = (event) => {
          this.fileURL = event.target.result;
          return true;
        };
        reader.readAsDataURL(this.fileSelected);
      } else {
        error_notification('Désolé, une erreur est survenue.');
        console.log('FileReader API not supported');
      }
    },
    // Chek file size and extesion
    async checkFile() {
      if (!this.checkExtension()) {
        error_notification(
          'Les formats valides sont les suivants: jpg, jpeg, png et pdf.'
        );
        return false;
      }

      if (!this.checkFileSize()) {
        error_notification('Le fichier est trop volumineux (max 1MO).');
        return false;
      }

      return true;
    },

    // Check file size 0-0
    checkFileSize() {
      const maxFileSize = 5_000_000; // == 5 MO
      if (this.fileSelected.size > maxFileSize) {
        return false;
      }
      return true;
    },

    // get file extension
    getFileExtension() {
      let fileExt = this.fileSelected.type.split('/');
      if (!fileExt[1]) return false;
      return fileExt[1];
    },

    // Check file extension
    checkExtension() {
      const allowedFileType = ['jpg', 'jpeg', 'png', 'pdf'];

      let isValid = allowedFileType.find(
        (type) => type == this.getFileExtension()
      );
      if (isValid) return true;
      return false;
    },

    async callUploadDocument(payload) {
      try {
        const fetch = require('node-fetch');

        const response = await fetch(
            `${process.env.VUE_APP_FFME_BACK_URL}/api/kyclemonway/uploadDocument`,
            {
              method: 'POST',
              headers: {
                'Authorization': "Bearer " + localStorage.getItem(process.env.VUE_APP_AUTH_TOKEN_NAME),
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({data : payload, accountId: this.currentStructureId}),
            }
        );
        const json = await response.json();
        console.log('response call upload====>', json);
        if (json.uploadDocument) {
          // success_notification('Document envoyé');
          return { ok: true };
          // this.$emit('onNextStep');
        } else if (
          json.error.message ==
          'A document of the same type is already waiting for review'
        ) {
          error_notification(`Ce document est déjà télécharger`);
          return { ok: false };
        } else if (json.error.message == 'DUPLICATED_DOCUMENT_FOUND') {
          error_notification(`Ce Type de document est déjà télécharger`);
          return { ok: false };
        } else if (
          json.error.message == 'Upload blocked for this type of document'
        ) {
          error_notification(`Téléchargement bloqué pour ce type de document`);
          return { ok: false };
        }
        // error_notification(`Veuillez réessayer plus tard`);
      } catch (error) {
        console.log('erro r:', error);
        // error_notification(`${error}`);
      }
    },

    async uploadDocument(payloadS3, docS3ID, typeDocument) {
      let extension = payloadS3.key.split('.').pop();
      console.log('extension', extension);

      this.axios
        .post(process.env.VUE_APP_FFME_BACK_URL + '/api/media-upload', payloadS3, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem(process.env.VUE_APP_AUTH_TOKEN_NAME)
          }
        })
        .then(async (response) => {
          if (
            response.data &&
            response.data.location &&
            response.status &&
            response.status === 200
          ) {
            let responseFile = {
              idDocument: docS3ID,
              url: response.data.location,
              filename: typeDocument + '.' + extension,
              date: this.$moment().toISOString(),
            };
            console.log('responseFile ---->', responseFile);

            // this.$emit('onFileUploaded', responseFile);

            await this.fileUploaded(responseFile);

            this.loading = false;
            return true;
          } else {
            error_notification();
            this.loading = false;
            console.log('Error on upload');
          }
        })
        .catch((error) => {
          this.loading = false;
          error_notification();
          console.log(error.toString());
        });
    },
    //update doc file in DOC_DOCUMENT
    async fileUploaded(file) {
      if (!file) return;
      let payload = {
        ID_Document: file.idDocument,
        CheminDocument: file.url,
        NomDocument: file.filename,
      };

      console.log('fileUploaded payload hasura', payload);
      try {
        await this.updateDocumentKyc(payload);
        return true;
      } catch (e) {
        error_notification(
          'Oups, une erreur est survenue lors du téléchargement du document...'
        );
      }
    },

    //replace and re-upload file with status rejected
    async reUploadFile(data) {
      this.$refs.fileInput.click();
      console.log('reUploadFile data', data);
      this.currentDocSelected = data;
      // console.log('payloadDocumentKyc : ', payloadDocumentKyc);
      // const saveDocument = await this.callUploadDocument(payloadDocumentKyc);
      // if (saveDocument.ok) {
      //   await this.uploadDocument(
      //     this.payloadS3ProcesVerbal,
      //     'piece_proces_verbal'
      //   );
      // }

      const payload = {
        ID_Document: 1521,
        CheminDocument: 'payload.CheminDocument',
        NomDocument: 'payload.NomDocument',
      };
      // const test = await this.updateDocumentKyc(payload);
      // console.log('reUploadFile test', test);
    },
  },
};
</script>

<style lang="scss" scoped>
.stepper-title {
  font-family: 'Roboto';
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: -1px;
  text-align: left;
}

.stepper-table-head {
  background: #39558b !important;

  .table-content {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #ffffff;
  }
}
.status {
  height: 13.333330154418945px;
  width: 13.333332061767578px;
  left: 3.333343505859375px;
  top: 3.3330078125px;
  border-radius: 50%;
  &.accepted {
    background: #00be76;
  }
  &.declined {
    background: red;
  }
  &.waiting {
    background: orange;
  }
}
.stepper-table-content {
  //styleName: Body/14px Deci;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  border-top: 0px !important;
}

table td {
  border: none !important;
}

.stepper-menu-title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
}

.stepper-menu-subtitle {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
}

.stepper-action {
  //position: absolute;
  //bottom: 0;
  //right: 0;
}

.stepper-info {
  background: rgba(8, 43, 110, 0.1) !important;
  //border: 2px solid #082B6E;
}

.stepper-info-text {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  /* or 143% */

  display: flex;
  align-items: center;

  /* Primary/Royal */

  color: #082b6e;

  border-left: 2px solid #082b6e !important;
}
</style>
